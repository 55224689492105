import styled from 'styled-components'
import {
  sh,
  primaryColor,
  sm,
  themeColor,
  xl,
  lg,
  xll,
  md,
} from 'config/variables'
import { images } from 'config/images'

export const ContactAddressStyle = styled.section`
  text-align: center;
  position: relative;
  padding-top: 200px;
  margin-bottom: 60px;
  margin-top: 30px;
  ${lg(`
    padding-top: 140px;
    margin-bottom: 40px;
  `)}
  ${sm(`
    padding-top: 80px;
  `)}
  .heading-red {
    background-image: linear-gradient(100deg, #3d3d3d, #ff4d63);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &:after{
    content: '';
    position: absolute;
    width: 787px;
    height: 330px;
    background: url(${images.footermap}) no-repeat center;
    top: 0;
    right: 0;
    ${lg(`
      width: 600px;
      background-size: 600px;
      height: 280px;
    `)}
    ${md(`
      width: 500px;
      background-size: 500px;
      height: 230px;
    `)}
    ${sm(`
      width: 320px;
      background-size: 320px;
      height: 150px;
    `)}
  }
  .container{
    position: relative;
    z-index: 1;
    .address-content-wrapper { 
      width: 100%; 
      .address-listing {
        padding: 30px 0;
          ${sm(`
            padding: 20px 40px;
          `)}
          .slick-slider{
            user-select: text;
            ${xl(`
              padding: 0 40px;
            `)}
            ${lg(`
              padding: 0 40px;
            `)}
            ${sm(`
              padding: 0;
            `)}
            .address-blk {
              max-width: 300px;
              padding: 0 20px;
              text-align: left;
              h5 {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.17px;
                margin-bottom: 8px;
                a{
                  color: #3d3d3d;
                  transition: all 0.3s ease;
                  text-decoration: none;
                  &:hover{
                    color: #EF5366;
                  }
                }
              }
              p {
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 15px;
                ${sh(`
                    font-size: 15px;
                    line-height: 25px;
                  `)}
              }
              a.call {
                font-size: 16px;
                line-height: 26px;
                font-weight: 500;
                color: ${primaryColor};
                transition: all 0.3s ease;
                position: relative;
                text-decoration: none;
                ${sh(`
                    font-size: 15px;
                    line-height: 25px;
                  `)}
                ${sm(`
                    font-size: 14px;
                    line-height: 18px;
                    `)}
                &:hover {
                  color: ${themeColor};
                }
              }
            }
          }
          .slick-arrow {
            width: 32px;
            position: absolute;
            padding: 0;
            top: 50%;
            z-index: 9;
            height: 24px;
            background-color: transparent;
            margin-top: -12px;
            transition: all 0.3s ease;
            outline: none;
            opacity: 1;
            border: 0;
            cursor: pointer;
            svg{
              width: 100%;
            }
            ${md(`
              width: 25px;
            `)}
            &:hover {
              opacity: 0.8;
            }
            &.slick-prev {
              left: -100px;
              ${xll(`
                left: -38px;
              `)}
              ${xl(`
                left: -10px;
              `)}
              ${lg(`
                left: -10px;
              `)}
              ${sm(`
                left: -42px;
              `)}
            }
            &.slick-next {
              right: -100px;
              ${xll(`
                right: -38px;
              `)}
              ${xl(`
                right: -10px;
              `)}
              ${lg(`
                right: -10px;
              `)}
              ${sm(`
                right: -42px;
              `)}
            }
          }
      }
      .follow-us-block{
          text-align: left;
          padding-left: 20px;
          h5{
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.17px;
            margin-bottom: 15px;
          }
          .social-icon{
              display: flex;
              a{
                  transition: all 0.3s ease;
                  margin-right: 20px;
                  &:hover {
                    transform: translateY(-5px);
                  }
                  img{
                      display: block;
                  }
              }
          }
      }
    }
  }
`
