import React, { Component } from 'react'
import LazyLoad from 'react-lazyload'

class Picture extends Component {
  render() {
    return (
      <LazyLoad height={this.props.height} once offset={this.props.offset}>
        <picture>
          <source
            srcSet={this.props.srcSet}
            media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)"
          />
          <img
            className={this.props.className}
            src={this.props.src}
            alt={this.props.alt}
          />
        </picture>
      </LazyLoad>
    )
  }
}

export default Picture
