import React, { Component } from 'react'
import LazyLoad from 'react-lazyload'
import { images } from 'config/images'
import { Link, navigate } from 'gatsby'
import { FooterStyle } from './Footer.styles'
import Picture from 'components/Picture/Picture'
import loadable from '@loadable/component'
import 'slick-carousel/slick/slick.css'
import { EXTERNAL_LINK, isWindowDefined } from 'config/utils'
import HubSpotFooterForm from 'components/HubSpotFooterForm/HubSpotFooterForm'

const ReactSlick = loadable.lib(() => import('react-slick'))
const PPCGetInTouchForm = loadable(() =>
  import('components/PPCGetInTouchForm/PPCGetInTouchForm')
)

const SocialMedia = [
  {
    src: images.iconFacebook,
    srcHvr: images.iconFacebookHvr,
    alt: 'Facebook',
    URL: 'https://www.facebook.com/simform/',
  },
  {
    src: images.iconLinkedin,
    srcHvr: images.iconLinkedinHvr,
    alt: 'linkedin',
    URL: 'https://www.linkedin.com/company/simform/',
  },
  {
    src: images.iconTwitter,
    srcHvr: images.iconTwitterHvr,
    alt: 'Twitter',
    URL: 'https://twitter.com/simform/',
  },
  {
    src: images.iconYoutube,
    srcHvr: images.iconYoutubeHvr,
    alt: 'Youtube',
    URL: 'https://www.youtube.com/channel/UCCY8HMCxj_RwI0xNJWVVGZg/',
  },
  {
    src: images.iconMedium,
    srcHvr: images.iconMediumHvr,
    alt: 'Medium',
    URL: 'https://medium.com/simform-engineering/',
  },
]

const Links = [
  {
    linkTitle: 'About Us',
    linkURL: '/about-us/',
  },
  {
    linkTitle: 'Our Partners',
    linkURL: '/technology-partners/',
    type: EXTERNAL_LINK,
  },
  {
    linkTitle: 'Areas We Serve',
    linkURL: '/areas-we-serve/',
    type: EXTERNAL_LINK,
  },
  {
    linkTitle: 'Careers',
    linkURL: '/careers/',
  },
  {
    linkTitle: 'Contact us',
    linkURL: '/contact/',
  },
]

const resourcesList = [
  {
    linkTitle: 'Blog',
    linkURL: '/blog/',
    type: EXTERNAL_LINK,
  },
  {
    linkTitle: 'Ebooks',
    linkURL: '/ebooks/',
    type: EXTERNAL_LINK,
  },
  {
    linkTitle: 'Case Studies',
    linkURL: '/case-studies/',
    type: EXTERNAL_LINK,
  },
]
const servicesList = [
  {
    linkTitle: 'Product and Platform Engineering',
    linkURL: '/services/digital-product-engineering/',
  },
  {
    linkTitle: 'Cloud and DevOps Engineering',
    linkURL: '/services/cloud-native-engineering/',
  },
  {
    linkTitle: 'Data Engineering',
    linkURL: '/services/data-engineering/',
    type: EXTERNAL_LINK,
  },
  {
    linkTitle: 'AI/ML Engineering',
    linkURL: '/services/gen-ai-ml-development/',
    type: EXTERNAL_LINK,
  },
  {
    linkTitle: 'Digital and Experience Engineering',
    linkURL: '/services/digital-experience-design/',
    type: EXTERNAL_LINK,
  },
]

const AddressContents = [
  {
    countryName: 'Orlando',
    address: '111 North Orange Avenue Suite 800, Orlando, FL 32801, USA',
    tel: 'Call us now',
    telTag: 'tel:13212372727',
  },
  {
    countryName: 'Dubai',
    address: '1505 Tiffany Towers, Cluster W, Jumeirah Lake Towers, Dubai',
    tel: 'Call us now',
    telTag: 'tel:13212372727',
  },
  {
    countryName: 'Canada',
    address: '15-115 - Bentall II Vancouver BC, V7X 1M8',
    tel: 'Call us now',
    telTag: 'tel:13212372727',
  },
  {
    countryName: 'India',
    address:
      '5th Floor, 501 Binori Bsquare2, Nr Double Tree By Hilton, Ambli - Bopal Rd, Ahmedabad, Gujarat 380058',
    tel: 'Call us now',
    telTag: 'tel:+912717452402',
  },

  // {
  //   countryName: 'San Francisco',
  //   address: '388 Market Street, Suite 1300 San Francisco, CA 94111 USA',
  //   tel: 'Call us now',
  //   telTag: 'tel:13212372727',
  // },

  // {
  //   countryName: 'Los Angeles',
  //   address: '700 S Flower Street, Suite 1000, Los Angeles, CA 90017 USA',
  //   tel: 'Call us now',
  //   telTag: 'tel:13212372727',
  // },
  // {
  //   countryName: 'San Diego',
  //   address: '2305 Historic Decatur Road, Suite 100, San Diego, CA 92106 USA',
  //   tel: 'Call us now',
  //   telTag: 'tel:13212372727',
  // },
  // {
  //   countryName: 'Dallas',
  //   address: '17250 Dallas Pkwy Dallas,TX 75248 USA',
  //   tel: 'Call us now',
  //   telTag: 'tel:13212372727',
  // },
  // {
  //   countryName: 'Austin',
  //   address: '701 Tillery St #12, Austin, TX 78702 USA',
  //   tel: 'Call us now',
  //   telTag: 'tel:13212372727',
  // },
  // {
  //   countryName: 'Chicago',
  //   address: '73 West Monroe Street, Chicago, IL 60603 USA',
  //   tel: 'Call us now',
  //   telTag: 'tel:13212372727',
  // },
  // {
  //   countryName: 'Boston',
  //   address: '120 St James Ave Floor 6, Boston, MA 02116 USA',
  //   tel: 'Call us now',
  //   telTag: 'tel:13212372727',
  // },
  // {
  //   countryName: 'Houston',
  //   address: '2500 Yale St Suite B2, Houston, TX 77008 USA',
  //   tel: 'Call us now',
  //   telTag: 'tel:13212372727',
  // },
  // {
  //   countryName: 'New York',
  //   address: '44 Court Street Suite, 1217 Brooklyn, NY 11201 USA',
  //   tel: 'Call us now',
  //   telTag: 'tel:13212372727',
  // },
]

const settings = {
  dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  centerPadding: 0,
  touchMove: false,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 3,
        touchMove: true,
      },
    },
    {
      breakpoint: 766,
      settings: {
        slidesToShow: 2,
        touchMove: true,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        touchMove: true,
      },
    },
  ],
}

function SamplePrevArrow(props) {
  const { onClick } = props
  return (
    <button className="slick-arrow slick-prev" onClick={onClick}>
      <svg width="32" height="25" viewBox="0 0 32 25">
        <path
          fill="#3D3D3D"
          fillRule="evenodd"
          d="M0 12a1.22 1.22 0 0 1 0-.13v-.11a1.25 1.25 0 0 1 0-.13l.05-.1.07-.12.07-.09.08-.1L11 .39a1.3 1.3 0 0 1 1.82 0 1.25 1.25 0 0 1 0 1.79l-8.43 8.56h26.32a1.3 1.3 0 0 1 0 2.59H4.29l8.55 8.55a1.25 1.25 0 0 1 0 1.79 1.3 1.3 0 0 1-1.82 0L.37 13l-.08-.09-.08-.09-.06-.11-.06-.11a1.18 1.18 0 0 1 0-.12 1.22 1.22 0 0 1 0-.12 1.16 1.16 0 0 1 0-.12 1.2 1.2 0 0 1 0-.13L0 12z"
        />
      </svg>
    </button>
  )
}

function SampleNextArrow(props) {
  const { onClick } = props
  return (
    <button className="slick-arrow slick-next" onClick={onClick}>
      <svg width="32" height="25" viewBox="0 0 32 25">
        <path
          fill="#3D3D3D"
          fillRule="evenodd"
          d="M32 12a1.22 1.22 0 0 0 0-.13v-.11a1.25 1.25 0 0 0 0-.13l-.05-.1-.07-.12-.07-.09-.08-.1L21 .39a1.3 1.3 0 0 0-1.82 0 1.25 1.25 0 0 0 0 1.79l8.43 8.56H1.29a1.3 1.3 0 0 0 0 2.59h26.42l-8.55 8.55a1.25 1.25 0 0 0 0 1.79 1.3 1.3 0 0 0 1.82 0L31.63 13l.08-.09.08-.09.06-.11.06-.11a1.18 1.18 0 0 0 0-.12 1.22 1.22 0 0 0 0-.12 1.16 1.16 0 0 0 0-.12 1.2 1.2 0 0 0 0-.13L32 12z"
        />
      </svg>
    </button>
  )
}

class Footer extends Component {
  render() {
    const {
      name,
      img,
      img2x,
      description,
      showGreatPlaceLogo,
      useCustomeForm,
    } = this.props
    let element = document.getElementsByClassName('content-area services-page')
      .length
    return (
      <FooterStyle className={element === 1 ? 'service-footer' : ''}>
        <div className="address-row-wrap">
          <div className="container">
            <div className="address-row">
              <h5>Areas We Serve</h5>
              <div className="address-listing">
                <ReactSlick>
                  {({ default: Slider }) => (
                    <Slider {...settings}>
                      {AddressContents.map(addressProps => {
                        return (
                          <div
                            key={addressProps.countryName}
                            className="address-single"
                          >
                            {addressProps.URL ? (
                              <h6>
                                <a href={addressProps.URL}>
                                  {addressProps.countryName}
                                </a>
                              </h6>
                            ) : (
                              <h6>{addressProps.countryName}</h6>
                            )}
                            <p>{addressProps.address}</p>
                            <a href={addressProps.telTag}>{addressProps.tel}</a>
                          </div>
                        )
                      })}
                    </Slider>
                  )}
                </ReactSlick>
              </div>
            </div>
          </div>
        </div>
        <div className="foot-info-row-wrap">
          <div className="container">
            {element === 1 ? (
              <div className="footer-form">
                <div className="form-part">
                  <h3>Let’s talk</h3>
                  {useCustomeForm ? (
                    <PPCGetInTouchForm CTAtext={'Send'} />
                  ) : (
                    <>
                      <HubSpotFooterForm
                        region="na1"
                        portalId="22295906"
                        formId="5a81a1cb-5052-44cb-859b-00a73b88e157"
                      />
                    </>
                  )}
                </div>
                <div className="admin-info">
                  <div className="img-blk">
                    <Picture
                      height={110}
                      offset={2000}
                      srcSet={img2x}
                      src={img}
                      alt="admin"
                    />
                  </div>
                  <h3>{name}</h3>
                  <p>{description}</p>
                  <a href="tel:13212372727" className="btn-call">
                    Call Us Now
                  </a>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="foot-info-row">
              <div className="top-row">
                <div className="foot-logo">
                  <Link to="/">
                    <img src={images.logo} alt="Logo" width="161" height="30" />
                  </Link>
                </div>
                <div className="social-icon">
                  {SocialMedia.map((SocialMediaProps, i) => {
                    return (
                      <a
                        key={i}
                        href={SocialMediaProps.URL}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                      >
                        <img
                          src={SocialMediaProps.src}
                          alt={SocialMediaProps.alt}
                          height="19"
                          width="19"
                        />
                        <img
                          className="hvr"
                          src={SocialMediaProps.srcHvr}
                          alt=""
                          height="19"
                          width="19"
                        />
                      </a>
                    )
                  })}
                </div>
                <div className="foot-right">
                  {showGreatPlaceLogo ? (
                    <LazyLoad height={95} once offset={2000}>
                      <img
                        src={images.footRatingImage}
                        alt="Google Page Speed"
                        width="200"
                      />
                    </LazyLoad>
                  ) : (
                    <div className="badge-rating-card">
                      <div className="badge-rating-item">
                        <a
                          href="https://clutch.co/profile/simform"
                          target="_blank"
                        >
                          <img src={images.footClutchLogo} alt="clutch" />
                        </a>
                      </div>
                      <div className="badge-rating-item">
                        <a
                          href="https://www.g2.com/products/simform/reviews"
                          target="_blank"
                        >
                          <img src={images.foot_G2_logo} alt="g2" />
                        </a>
                      </div>
                    </div>
                  )}
                  <a
                    href="//www.dmca.com/Protection/Status.aspx?ID=d95ffd61-b1ff-4740-a5ab-7811669805cf"
                    title="DMCA.com Protection Status"
                    className="dmca-badge"
                    target="_blank"
                  >
                    {' '}
                    <img
                      src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-04.png?ID=d95ffd61-b1ff-4740-a5ab-7811669805cf"
                      alt="DMCA.com Protection Status"
                      width="100"
                      height="20"
                    />
                  </a>
                </div>
              </div>
              <div className="foot-link-row">
                <div className="link-col  ">
                  <h4 className="foot-link-title">About Simform</h4>
                  <ul>
                    {Links.map(linkProps => {
                      return (
                        <li key={linkProps.linkTitle}>
                          {linkProps.type !== EXTERNAL_LINK ? (
                            <Link to={linkProps.linkURL}>
                              {linkProps.linkTitle}
                            </Link>
                          ) : (
                            <a
                              href={`${
                                isWindowDefined ? window.location.origin : ''
                              }${linkProps.linkURL}`}
                            >
                              {linkProps.linkTitle}
                            </a>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="link-col">
                  <h4 className="foot-link-title">Services</h4>
                  <ul>
                    {servicesList.map(services => {
                      return (
                        <li key={services.linkTitle}>
                          {services.type !== EXTERNAL_LINK ? (
                            <Link to={services.linkURL}>
                              {services.linkTitle}
                            </Link>
                          ) : (
                            <a
                              href={`${
                                isWindowDefined ? window.location.origin : ''
                              }${services.linkURL}`}
                            >
                              {services.linkTitle}
                            </a>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="link-col last-col">
                  <h4 className="foot-link-title">Resources</h4>
                  <ul>
                    {resourcesList.map(resourcesItem => {
                      return (
                        <li key={resourcesItem.linkTitle}>
                          {resourcesItem.type !== EXTERNAL_LINK ? (
                            <Link to={resourcesItem.linkURL}>
                              {resourcesItem.linkTitle}
                            </Link>
                          ) : (
                            <a
                              href={`${
                                isWindowDefined ? window.location.origin : ''
                              }${resourcesItem.linkURL}`}
                            >
                              {resourcesItem.linkTitle}
                            </a>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            <p>
              Copyright © {new Date().getFullYear()} Simform. All Rights
              Reserved.
            </p>
          </div>
        </div>
      </FooterStyle>
    )
  }
}

export default Footer
