const BUCKET_PREFIX = 'https://simform-website-2019.s3.amazonaws.com'

export const images = {
  //////////
  // simformVideo: `${BUCKET_PREFIX}/2-Final_Simform4k.mp4`, video file ////////////
  //////////
  logo: require('images/logo.svg'),
  customSoftwareIcon: require('images/CustomSoftwareDevelopment.svg'),
  softwareTesting: require('images/SoftwareTesting.svg'),
  softwareTesting2: require('images/software-testing.svg'),
  enterprise: require('images/Enterprisemobile.svg'),
  webAppDevIcon: require('images/web-app-development.svg'),
  dedicatedSoftware: require('images/Dedicatedsoftware.svg'),
  productDevelopment: require('images/ProductDevelopmentServices.svg'),
  paternBackground: require('images/back-patren.png'),
  redbull: require('images/rebbull.svg'),
  redbullg: require('images/rebbullg.svg'),
  boa: require('images/boa.png'),
  boag: require('images/boag.png'),
  cisco: require('images/cisco.png'),
  ciscog: require('images/ciscog.png'),
  cameo: require('images/cameo.png'),
  cameog: require('images/cameog.png'),
  iconTick: require('images/icon-tick.svg'),
  adoptingProcessMen: require('images/adopting-process-men.png'),
  iconTeam: require('images/assemble-team.svg'),
  iconSprint: require('images/sprint-planning.svg'),
  iconTech: require('images/tech-architech.svg'),
  iconIterate: require('images/iterateIcon.svg'),
  iconCodeReviews: require('images/code-reviews-icon.svg'),
  iconStandUps: require('images/stand-ups-icon.svg'),
  iconFlowArrow1: require('images/flow-arrow1.svg'),
  iconFlowArrow2: require('images/flow-arrow2.svg'),
  iconFlowArrow3: require('images/flow-arrow3.svg'),
  iconFlowArrow4: require('images/flow-arrow4.svg'),
  iconFlowArrow5: require('images/flow-arrow5.svg'),
  iconFlowArrow6: require('images/flow-arrow6.svg'),
  gauranteeTeam: require('images/gaurantee-team.png'),
  gauranteeTeam2x: require('images/gaurantee-team@2x.png'),
  dvdReplication1: require('images/dvd-replication.png'),
  dvdReplication2: require('images/dvd-replication2.png'),
  iconFacebook: require('images/facebook.svg'),
  iconFacebookHvr: require('images/facebook-hvr.svg'),
  iconLinkedin: require('images/linkedin.svg'),
  iconLinkedinHvr: require('images/linkedin-hvr.svg'),
  iconInstagram: require('images/instagram.svg'),
  iconTwitter: require('images/twitter.svg'),
  iconTwitterHvr: require('images/twitter-hvr.svg'),
  iconYoutube: require('images/youtube.svg'),
  iconYoutubeHvr: require('images/youtube-hvr.svg'),
  iconMedium: require('images/medium.svg'),
  iconMediumHvr: require('images/medium-hvr.svg'),
  footRatingImage: require('images/foot-rating-image@2x.png'),
  footClutchLogo: require('images/foot-clutch-logo.svg'),
  downArrow: require('images/down-arrow.svg'),
  starActive: require('images/star-active.svg'),
  quote: require('images/quote.svg'),
  starInactive: require('images/star-inactive.svg'),
  serviceUser1: require('images/service-user-1.png'),
  serviceUser12x: require('images/service-user-1@2x.png'),
  serviceUser2: require('images/service-user-2.png'),
  serviceUser22x: require('images/service-user-2@2x.png'),
  serviceUser3: require('images/service-user-3.png'),
  serviceUser32x: require('images/service-user-3@2x.png'),
  serviceUser4: require('images/service-user-4.png'),
  serviceUser42x: require('images/service-user-4@2x.png'),
  serviceUser5: require('images/service-user-5.png'),
  serviceUser52x: require('images/service-user-5@2x.png'),
  serviceUser6: require('images/service-user-6.png'),
  serviceUser62x: require('images/service-user-6@2x.png'),
  connector: require('images/connector.svg'),
  ServiceTechArchitecture: require('images/ServiceTechArchitecture.svg'),
  ServiceStandups: require('images/ServiceStandups.svg'),
  ServiceSprintPlanning: require('images/ServiceSprintPlanning.svg'),
  ServiceEnterpriseAaplication: require('images/ServiceEnterpriseAaplication.svg'),
  ServiceCodeReviews: require('images/ServiceCodeReviews.svg'),
  ServiceBigdata: require('images/ServiceBigdata.svg'),
  ppcBG: require('images/ppc-bg.svg'),
  redShape: require('images/red-shape.png'),
  faqDownArrow: require('images/faq-down-arrow.svg'),
  dotPatern: require('images/dot-patern.svg'),
  realTime: require('images/real-time.svg'),
  bigData: require('images/big-data.svg'),
  legacy: require('images/legacy.svg'),
  dashboard: require('images/dashboard.svg'),
  systemIntegration: require('images/system-integration.svg'),
  quoteGray: require('images/quote-gray.svg'),
  demoVideo: `${BUCKET_PREFIX}/simform-final-10sec.mp4`,
  freewireVideo: `${BUCKET_PREFIX}/freewire-main-anime.mp4`,
  swifttVideo: `${BUCKET_PREFIX}/Swift-shopper-main.mp4`,
  ResultihealthVideo: `${BUCKET_PREFIX}/ihealth1.mp4`,
  AppDeveVideo: `${BUCKET_PREFIX}/apple-watch.mp4`,
  ihealthIntroVideo: `${BUCKET_PREFIX}/ihealth2.mp4`,
  thankYouVideo: `${BUCKET_PREFIX}/Simform Thank you.mp4`,
  thankYouImage: require('images/thankyou.png'),
  mute: require('images/mute.svg'),
  unmute: require('images/unmute.svg'),
  iconClose: require('images/icon-close.svg'),
  cookieBg: require('images/Cookie-bg.png'),
  iconEnterprise: require('images/icon-enterprise-mobility-solution.svg'),
  iconBusinessApp: require('images/icon-business-app.svg'),
  iconHybrid: require('images/icon-hybrid.svg'),
  iconEcommerceWeb: require('images/icon-ecommerce-web.svg'),
  iconBackendNode: require('images/icon-backend-node-js.svg'),
  iconProgressiveWeb: require('images/icon-progressive-web.svg'),
  iconReactJs: require('images/icon-react-js.svg'),
  RightArrow: require('images/rotated-right-arrow.svg'),
  topSign: require('images/top-sign.svg'),
  bottomSign: require('images/bottom-sign.svg'),
  contactLadySm: require('images/contact-lady-sm.png'),
  contactHeadingIcon: require('images/contact-heading-icon.svg'),
  iconDownload: require('images/icon-download.svg'),
  iconTickWhite: require('images/icon-tick-white.svg'),
  selectArrow: require('images/select-arrow.svg'),
  pdivider1: require('images/pdivider-1.svg'),
  pdivider2: require('images/pdivider-2.svg'),
  topTalent: require('images/top-talent.svg'),
  zeroRampUp: require('images/zero-ramp-up.svg'),
  managedProcess: require('images/managed-process.svg'),
  reporting: require('images/reporting.svg'),
  directAccess: require('images/direct-access-to-experts.svg'),
  projectSpecs: require('images/project-specs-and-scoping.svg'),
  ipSecurity: require('images/ip-security.svg'),
  easyAdmin: require('images/easy-admin.svg'),
  infrastructure: require('images/infrastructure.svg'),
  talent: require('images/talent-management.svg'),
  delivery: require('images/delivery-management.svg'),
  consulting: require('images/consulting-services.svg'),
  testing: require('images/testing.svg'),
  insuarance: require('images/insuarance.svg'),
  codeDocumentation: require('images/code-documentation.svg'),
  call: require('images/call.svg'),
  elearning1: require('images/elearning1.png'),
  elearning12: require('images/elearning1@2x.png'),
  elearning2: require('images/elearning2.png'),
  elearning22: require('images/elearning2@2x.png'),
  elearning3: require('images/elearning3.png'),
  elearning32: require('images/elearning3@2x.png'),
  wealthManagement1: require('images/wealth-management1.png'),
  wealthManagement12: require('images/wealth-management1@2x.png'),
  wealthManagement2: require('images/wealth-management2.png'),
  wealthManagement22: require('images/wealth-management2@2x.png'),
  wealthManagement3: require('images/wealth-management3.png'),
  wealthManagement32: require('images/wealth-management3@2x.png'),
  ecommerce1: require('images/ecommerce1.png'),
  ecommerce12x: require('images/ecommerce1@2x.png'),
  ecommerce2: require('images/ecommerce2.png'),
  ecommerce22x: require('images/ecommerce1@2x.png'),
  ecommerce3: require('images/ecommerce3.png'),
  ecommerce32x: require('images/ecommerce3@2x.png'),
  mediastream1: require('images/mediastream1.png'),
  mediastream12x: require('images/mediastream1@2x.png'),
  mediastream2: require('images/mediastream2.png'),
  mediastream22x: require('images/mediastream2@2x.png'),
  mediastream3: require('images/mediastream3.png'),
  mediastream32x: require('images/mediastream3@2x.png'),
  enterprise1: require('images/enterprise1.png'),
  enterprise12x: require('images/enterprise1@2x.png'),
  enterprise2: require('images/enterprise2.png'),
  enterprise22x: require('images/enterprise2@2x.png'),
  enterprise3: require('images/enterprise3.png'),
  enterprise32x: require('images/enterprise3@2x.png'),
  fleet1: require('images/fleet1.png'),
  fleet12x: require('images/fleet1@2x.png'),
  fleet2: require('images/fleet2.png'),
  fleet22x: require('images/fleet2@2x.png'),
  fleet3: require('images/fleet3.png'),
  fleet32x: require('images/fleet3@2x.png'),
  oilgas1: require('images/oilgas1.png'),
  oilgas12x: require('images/oilgas1@2x.png'),
  oilgas2: require('images/oilgas2.png'),
  oilgas22x: require('images/oilgas2@2x.png'),
  oilgas3: require('images/oilgas3.png'),
  oilgas32x: require('images/oilgas3@2x.png'),
  educationElearning1: require('images/education-elearning1.png'),
  educationElearning12x: require('images/education-elearning1@2x.png'),
  educationElearning2: require('images/education-elearning2.png'),
  educationElearning22x: require('images/education-elearning2@2x.png'),
  educationElearning3: require('images/education-elearning3.png'),
  educationElearning32x: require('images/education-elearning3@2x.png'),
  sales1: require('images/sales1.png'),
  sales12x: require('images/sales1@2x.png'),
  sales2: require('images/sales2.png'),
  sales22x: require('images/sales2@2x.png'),
  sales3: require('images/sales3.png'),
  sales32x: require('images/sales3@2x.png'),
  marketing1: require('images/marketing1.png'),
  marketing12x: require('images/marketing1@2x.png'),
  marketing2: require('images/marketing2.png'),
  marketing22x: require('images/marketing2@2x.png'),
  marketing3: require('images/marketing3.png'),
  marketing32x: require('images/marketing3@2x.png'),
  smsemail1: require('images/smsemail1.png'),
  smsemail12x: require('images/smsemail1@2x.png'),
  smsemail2: require('images/smsemail2.png'),
  smsemail22x: require('images/smsemail2@2x.png'),
  smsemail3: require('images/smsemail3.png'),
  smsemail32x: require('images/smsemail3@2x.png'),
  shopping1: require('images/shopping1.png'),
  shopping12x: require('images/shopping1@2x.png'),
  shopping2: require('images/shopping2.png'),
  shopping22x: require('images/shopping2@2x.png'),
  shopping3: require('images/shopping3.png'),
  shopping32x: require('images/shopping3@2x.png'),
  shipping1: require('images/shipping1.png'),
  shipping12x: require('images/shipping1@2x.png'),
  shipping2: require('images/shipping2.png'),
  shipping22x: require('images/shipping2@2x.png'),
  shipping3: require('images/shipping3.png'),
  shipping32x: require('images/shipping3@2x.png'),
  sociallogin1: require('images/sociallogin1.png'),
  sociallogin12x: require('images/sociallogin1@2x.png'),
  sociallogin2: require('images/sociallogin2.png'),
  sociallogin22x: require('images/sociallogin2@2x.png'),
  sociallogin3: require('images/sociallogin3.png'),
  sociallogin32x: require('images/sociallogin3@2x.png'),
  weather1: require('images/weather1.png'),
  weather12x: require('images/weather1@2x.png'),
  weather2: require('images/weather2.png'),
  weather22x: require('images/weather2@2x.png'),
  weather3: require('images/weather3.png'),
  weather32x: require('images/weather3@2x.png'),
  inbuilt1: require('images/inbuilt1.png'),
  inbuilt12x: require('images/inbuilt1@2x.png'),
  inbuilt2: require('images/inbuilt2.png'),
  inbuilt22x: require('images/inbuilt2@2x.png'),
  inbuilt3: require('images/inbuilt3.png'),
  inbuilt32x: require('images/inbuilt3@2x.png'),
  educational1: require('images/educational1.png'),
  educational12x: require('images/educational1@2x.png'),
  educational2: require('images/educational2.png'),
  educational22x: require('images/educational2@2x.png'),
  educational3: require('images/educational3.png'),
  educational32x: require('images/educational3@2x.png'),
  socialmedia1: require('images/socialmedia1.png'),
  socialmedia12x: require('images/socialmedia1@2x.png'),
  socialmedia2: require('images/socialmedia2.png'),
  socialmedia22x: require('images/socialmedia2@2x.png'),
  socialmedia3: require('images/socialmedia3.png'),
  socialmedia32x: require('images/socialmedia3@2x.png'),
  athlete1: require('images/athlete1.png'),
  athlete12x: require('images/athlete1@2x.png'),
  athlete2: require('images/athlete2.png'),
  athlete22x: require('images/athlete2@2x.png'),
  athlete3: require('images/athlete3.png'),
  athlete32x: require('images/athlete3@2x.png'),
  webbased1: require('images/webbased1.png'),
  webbased12x: require('images/webbased1@2x.png'),
  webbased2: require('images/webbased2.png'),
  webbased22x: require('images/webbased2@2x.png'),
  webbased3: require('images/webbased3.png'),
  webbased32x: require('images/webbased3@2x.png'),
  videointer1: require('images/videointer1.png'),
  videointer12x: require('images/videointer1@2x.png'),
  videointer2: require('images/videointer2.png'),
  videointer22x: require('images/videointer2@2x.png'),
  videointer3: require('images/videointer3.png'),
  videointer32x: require('images/videointer3@2x.png'),
  inventory1: require('images/inventory1.png'),
  inventory12x: require('images/inventory1@2x.png'),
  inventory2: require('images/inventory2.png'),
  inventory22x: require('images/inventory2@2x.png'),
  inventory3: require('images/inventory3.png'),
  inventory32x: require('images/inventory3@2x.png'),
  retailweb1: require('images/retailweb1.png'),
  retailweb12x: require('images/retailweb1@2x.png'),
  retailweb2: require('images/retailweb2.png'),
  retailweb22x: require('images/retailweb2@2x.png'),
  retailweb3: require('images/retailweb3.png'),
  retailweb32x: require('images/retailweb3@2x.png'),
  bank1: require('images/bank1.png'),
  bank12x: require('images/bank1@2x.png'),
  bank2: require('images/bank2.png'),
  bank22x: require('images/bank2@2x.png'),
  bank3: require('images/bank3.png'),
  bank32x: require('images/bank3@2x.png'),
  mobilebus1: require('images/mobilebus1.png'),
  mobilebus12x: require('images/mobilebus1@2x.png'),
  mobilebus2: require('images/mobilebus2.png'),
  mobilebus22x: require('images/mobilebus2@2x.png'),
  mobilebus3: require('images/mobilebus3.png'),
  mobilebus32x: require('images/mobilebus3@2x.png'),
  mobbanking1: require('images/mobbanking1.png'),
  mobbanking12x: require('images/mobbanking1@2x.png'),
  mobbanking2: require('images/mobbanking2.png'),
  mobbanking22x: require('images/mobbanking2@2x.png'),
  mobbanking3: require('images/mobbanking3.png'),
  mobbanking32x: require('images/mobbanking3@2x.png'),
  b2b1: require('images/b2b1.png'),
  b2b12x: require('images/b2b1@2x.png'),
  b2b2: require('images/b2b2.png'),
  b2b22x: require('images/b2b2@2x.png'),
  b2b3: require('images/b2b3.png'),
  b2b32x: require('images/b2b3@2x.png'),
  doctor1: require('images/doctor1.png'),
  doctor12x: require('images/doctor1@2x.png'),
  doctor2: require('images/doctor2.png'),
  doctor22x: require('images/doctor2@2x.png'),
  doctor3: require('images/doctor3.png'),
  doctor32x: require('images/doctor3@2x.png'),
  publich1: require('images/publich1.png'),
  publich12x: require('images/publich1@2x.png'),
  publich2: require('images/publich2.png'),
  publich22x: require('images/publich2@2x.png'),
  publich3: require('images/publich3.png'),
  publich32x: require('images/publich3@2x.png'),
  mobecomm1: require('images/mobecomm1.png'),
  mobecomm12x: require('images/mobecomm1@2x.png'),
  mobecomm2: require('images/mobecomm2.png'),
  mobecomm22x: require('images/mobecomm2@2x.png'),
  mobecomm3: require('images/mobecomm3.png'),
  mobecomm32x: require('images/mobecomm3@2x.png'),
  mobilepos1: require('images/mobilepos1.png'),
  mobilepos12x: require('images/mobilepos1@2x.png'),
  mobilepos2: require('images/mobilepos2.png'),
  mobilepos22x: require('images/mobilepos2@2x.png'),
  mobilepos3: require('images/mobilepos3.png'),
  mobilepos32x: require('images/mobilepos3@2x.png'),
  stadium1: require('images/stadium1.png'),
  stadium12x: require('images/stadium1@2x.png'),
  stadium2: require('images/stadium2.png'),
  stadium22x: require('images/stadium2@2x.png'),
  stadium3: require('images/stadium3.png'),
  stadium32x: require('images/stadium3@2x.png'),
  videoapp1: require('images/videoapp1.png'),
  videoapp12x: require('images/videoapp1@2x.png'),
  videoapp2: require('images/videoapp2.png'),
  videoapp22x: require('images/videoapp2@2x.png'),
  videoapp3: require('images/videoapp3.png'),
  videoapp32x: require('images/videoapp3@2x.png'),
  hospital1: require('images/hospital1.png'),
  hospital12x: require('images/hospital1@2x.png'),
  hospital2: require('images/hospital2.png'),
  hospital22x: require('images/hospital2@2x.png'),
  hospital3: require('images/hospital3.png'),
  hospital32x: require('images/hospital3@2x.png'),
  mobilecrm1: require('images/mobilecrm1.png'),
  mobilecrm12x: require('images/mobilecrm1@2x.png'),
  mobilecrm2: require('images/mobilecrm2.png'),
  mobilecrm22x: require('images/mobilecrm2@2x.png'),
  mobilecrm3: require('images/mobilecrm3.png'),
  mobilecrm32x: require('images/mobilecrm3@2x.png'),
  iconGo: require('images/Go.svg'),
  iconPython: require('images/Python.svg'),
  iconRubyOnRails: require('images/RubyOnRails.svg'),
  iconAspNET: require('images/AspNET.svg'),
  iconNETCore: require('images/NETCore.svg'),
  iconPHP: require('images/PHP.svg'),
  iconNodeJS: require('images/NodeJS.svg'),
  iconJava: require('images/Java.svg'),
  iconElectron: require('images/Electron.svg'),
  iconNative: require('images/Native.svg'),
  iconTypeScript: require('images/TypeScript.svg'),
  iconXamarin: require('images/Xamarin.svg'),
  iconVue: require('images/Vue.svg'),
  iconReact: require('images/React.svg'),
  iconReactNative: require('images/ReactNative.svg'),
  iconWPF: require('images/WPF.svg'),
  iconAdnroid: require('images/Adnroid.svg'),
  iconJavaScript: require('images/JavaScript.svg'),
  iconAngularJS: require('images/AngularJS.svg'),
  iconiOS: require('images/iOS.svg'),
  iconUnity: require('images/Unity.svg'),
  iconSQLServer: require('images/SQLServer.svg'),
  iconGroup32: require('images/Group 32.svg'),
  iconCloudera: require('images/Cloudera.svg'),
  iconGroup42: require('images/CosmosDB.svg'),
  iconMySQL: require('images/MySQL.svg'),
  iconRethinkDB: require('images/RethinkDB.svg'),
  iconFirestore: require('images/Firestore.svg'),
  iconPostageSQL: require('images/PostageSQL.svg'),
  iconOracle: require('images/Oracle.svg'),
  iconhbase: require('images/hbase.svg'),
  iconMAPR: require('images/MAPR.svg'),
  iconHortonworks: require('images/Hortonworks.svg'),
  iconHadoop: require('images/Hadoop.svg'),
  iconFirebase: require('images/Firebase.svg'),
  iconCouchbase: require('images/Couchbase.svg'),
  iconNeptune: require('images/Neptune.svg'),
  iconRedis: require('images/Redis.svg'),
  iconMongoDB: require('images/MongoDB.svg'),
  iconcassandra: require('images/cassandra.svg'),
  iconElasticSearch: require('images/ElasticSearch.svg'),
  iconSuccessfactors: require('images/Successfactors.svg'),
  iconWordpress: require('images/Wordpress.svg'),
  iconMagento: require('images/Magento.svg'),
  iconCanvas: require('images/Canvas.svg'),
  iconLitmus: require('images/Litmus.svg'),
  iconLiferay: require('images/Liferay.svg'),
  iconShopify: require('images/Shopify.svg'),
  iconDocebo: require('images/Docebo.svg'),
  iconMoodle: require('images/Moodle.svg'),
  iconUmbraco: require('images/Umbraco.svg'),
  iconSitecore: require('images/Sitecore.svg'),
  iconTotara: require('images/Totara.svg'),
  iconAdapt: require('images/Adapt.svg'),
  iconGooglecloud: require('images/Google cloud.svg'),
  icontwilio: require('images/twilio.svg'),
  iconAlgolia: require('images/Algolia.svg'),
  iconTalend: require('images/Talend.svg'),
  iconSpluck: require('images/Spluck.svg'),
  iconAWS: require('images/AWS.svg'),
  iconAlfresco: require('images/Alfresco.svg'),
  iconStripe: require('images/Stripe.svg'),
  iconSharePoint: require('images/SharePoint.svg'),
  iconApigee: require('images/Apigee.svg'),
  iconPivotal: require('images/Pivotal.svg'),
  iconSalesforce: require('images/Salesforce.svg'),
  iconDocker: require('images/Docker.svg'),
  iconMulesoft: require('images/Mulesoft.svg'),
  iconNexmo: require('images/Nexmo.svg'),
  iconkubernetes: require('images/kubernetes.svg'),
  iconAzure: require('images/Azure.svg'),
  iconSoupUI: require('images/SoupUI.svg'),
  iconMochas: require('images/Mochas.svg'),
  iconAppium: require('images/Appium.svg'),
  iconHoverfly: require('images/Hoverfly.svg'),
  iconJMeter: require('images/JMeter.svg'),
  iconKatalon: require('images/Katalon.svg'),
  iconSelenium: require('images/Selenium.svg'),
  iconGatling: require('images/Gatling.svg'),
  iconSaucelabs: require('images/Saucelabs.svg'),
  iconTravisCI: require('images/TravisCI.svg'),
  iconCircleCI: require('images/CircleCI.svg'),
  iconCodeship: require('images/Codeship.svg'),
  icongradle: require('images/gradle.svg'),
  iconJenkins: require('images/Jenkins.svg'),
  footermap: require('images/footer-map.svg'),
  iconCallRed: require('images/icon-call-red.svg'),
  selectarrowred: require('images/select-arrow-red.svg'),
  eiconcontact: require('images/eicon-contact.svg'),
  eiconblog: require('images/eicon-blog.svg'),
  eiconcareer: require('images/eicon-career.svg'),
  eiconabout: require('images/eicon-about.svg'),
  eiconservice: require('images/eicon-service.svg'),
  hirenpic: require('images/hiren-pic.png'),
  hirenpic2x: require('images/hiren-pic@2x.png'),
  maitrikpic: require('images/maitrik-pic.png'),
  maitrikpic2x: require('images/maitrik-pic@2x.png'),
  pareshpic: require('images/paresh-pic.png'),
  pareshpic2x: require('images/paresh-pic@2x.png'),
  tejaspic: require('images/tejas-pic.png'),
  tejaspic2x: require('images/tejas-pic@2x.png'),
  ronakpic: require('images/ronak-pic.png'),
  ronakpic2x: require('images/ronak-pic@2x.png'),
  chintanpic: require('images/chintan-pic.png'),
  chintanpic2x: require('images/chintan-pic@2x.png'),
  juanpic: require('images/juan-pic.png'),
  juanpic2x: require('images/juan-pic@2x.png'),
  stevepic2x: require('images/steve-pic@2x.png'),
  christopherpic: require('images/christopher-pic.png'),
  christopherpic2x: require('images/christopher-pic@2x.png'),
  ravipic: require('images/ravi-pic.png'),
  ravipic2x: require('images/ravi-pic@2x.png'),
  martinpic: require('images/martin-pic.png'),
  martinpic2x: require('images/ravi-pic@2x.png'),
  zachpic2x: require('images/ravi-pic@2x.png'),
  tompic2x: require('images/ravi-pic@2x.png'),
  abdulmalikpic2x: require('images/abdulmalik-pic@2x.png'),
  ekimpic2x: require('images/ekim-pic@2x.png'),
  zekepic2x: require('images/abdulmalik-pic@2x.png'),
  rukayatpic2x: require('images/abdulmalik-pic@2x.png'),
  nathanpic2x: require('images/nathan-pic@2x.png'),
  zoltanpic2x: require('images/ervis-pic@2x.png'),
  andypic2x: require('images/andy-pic@2x.png'),
  rinatpic2x: require('images/rinat-pic@2x.png'),
  adelpic2x: require('images/adel-pic@2x.png'),
  christopheresplinpic2x: require('images/christopher-esplin-pic@2x.png'),
  davidpic2x: require('images/david-pic@2x.png'),
  annapic2x: require('images/anna-pic@2x.png'),
  iosLogo: require('images/ios-logo.svg'),
  androidLogo: require('images/android-logo.svg'),
  nodeLogo: require('images/nodejs-logo.svg'),
  awsglueeltLogo: require('images/aws-glue-etl-logo.svg'),
  processflow1: require('images/process-flow1.svg'),
  processflow2: require('images/process-flow2.svg'),
  processflow3: require('images/process-flow3.svg'),
  processflow4: require('images/process-flow4.svg'),
  processflow5: require('images/process-flow5.svg'),
  processflow6: require('images/process-flow6.svg'),
  processflow7: require('images/process-flow7.svg'),
  lennyperkins: require('images/lenny-perkins.png'),
  lennyperkins2x: require('images/lenny-perkins@2x.png'),
  lgQuote: require('images/lg-quote.svg'),
  loyaltyProgram: require('images/loyalty-program.svg'),
  accessMatch: require('images/access-match.svg'),
  planShop: require('images/plan-shop.svg'),
  kathryn: require('images/kathryn-lasater.png'),
  kathryn2x: require('images/kathryn-lasater@2x.png'),
  revenue: require('images/revenue.svg'),
  sdeal: require('images/sdeal.svg'),
  sanalytics: require('images/sanalytics.svg'),
  mdata: require('images/mdata.svg'),
  jawannSPic: require('images/jawann-swislow-pic.png'),
  Alex: require('images/Alex.png'),
  Alex2x: require('images/Alex@2x.png'),
  jawannSPic2x: require('images/jawann-swislow-pic@2x.png'),
  jawannSPic2: require('images/Jawann-reewireapp2.png'),
  jawannSPic22x: require('images/Jawann-Freewireapp2@2x.png'),
  checkLineIcons: require('images/check-line-icons.png'),
  benefitssecBg: require('images/benefitssec-bg.png'),
  iconSmarter: require('images/icon-smarter.svg'),
  iconCost: require('images/icon-cost.svg'),
  iconDowntime: require('images/icon-downtime.svg'),
  iconScalability: require('images/icon-scalability.svg'),
  iconResponsive: require('images/icon-responsive.svg'),
  iconServerside: require('images/icon-serverside.svg'),
  iconLoad: require('images/icon-load.svg'),
  iconLazilyload: require('images/icon-lazilyload.svg'),
  iconBapi: require('images/icon-bapi.svg'),
  iconShield: require('images/icon-shield.svg'),
  iconHealthBenefits: require('images/icon-health-benefits.svg'),
  iconLevelUp: require('images/icon-level-up-career.svg'),
  iconFlexibility: require('images/icon-flexibility-to-work.svg'),
  iconMentorshipAnd: require('images/icon-mentorship-and-learning.svg'),
  vinaymhetre: require('images/vinaymhetre.png'),
  vinaymhetre2x: require('images/vinaymhetre@2x.png'),
  contactHs: require('images/contact-hs.png'),
  contactHs2x: require('images/contact-hs@2x.png'),
  contactHiren: require('images/contactHiren.png'),
  contactHiren_2x: require('images/contactHiren_2x.png'),
  contactJignesh: require('images/contact-jignesh.png'),
  contactJignesh2x: require('images/contact-jignesh@2x.png'),
  contactCruben: require('images/contact-cruben.png'),
  contactCruben2x: require('images/contact-cruben@2x.png'),
  contactKellyPowell: require('images/contact-kelly-powell.png'),
  contactKellyPowell2x: require('images/contact-kelly-powell@2x.png'),
  contactMichaelCullinan: require('images/contact-michael-cullinan.png'),
  contactMichaelCullinan2x: require('images/contact-michael-cullinan@2x.png'),
  smachineLearning: require('images/smachine-learning.svg'),
  svrArAppDev: require('images/svr-ar-app-dev.svg'),
  sInternetofThings: require('images/sinternetofthings.svg'),
  sblockchain: require('images/sblockchain.svg'),
  sartificialIntelligence: require('images/artificial-intelligence.svg'),
  scloudApp: require('images/scloud-app.svg'),
  sappMaintenance: require('images/sapp-maintenance.svg'),
  sapiIntegrationService: require('images/sapi-integration-service.svg'),
  scustomApiDevServices: require('images/scustom-api-dev-services.svg'),
  sapiAsAService: require('images/sapi-as-a-service.svg'),
  sautomatedApi: require('images/sautomated-api.svg'),
  smicroServices: require('images/smicro-services.svg'),
  sautomationTesting: require('images/sautomation-testing.svg'),
  smobileAppTestingServices: require('images/smobile-app-testing-services.svg'),
  smanualTestingServices: require('images/smanual-testing-services.svg'),
  sdevOpsAgileService: require('images/sdev-ops-agile-service.svg'),
  sproductDiscovery: require('images/sproduct-discovery.svg'),
  suxDesign: require('images/sux-design.svg'),
  sprogressiveWebApp: require('images/sprogressive-web-app.svg'),
  sappDevService: require('images/sapp-dev-service.svg'),
  sproductTransformation: require('images/sproduct-transformation.svg'),
  sCustomwebAppEnterprises: require('images/scustomweb-app-enterprises.svg'),
  harsimranSingh: require('images/harsimran-singh-virk.png'),
  harsimranSingh2x: require('images/harsimran-singh-virk@2x.png'),
  processFlowLightBlue: require('images/process-flow-light-blue.svg'),
  processFlowLightBlueDown: require('images/process-flow-light-blue-down.svg'),
  fihNew: require('images/fihnew.png'),
  fihOld: require('images/fihold.png'),
  stubbsNew: require('images/stubbsnew.png'),
  stubbsOld: require('images/stubbsold.png'),
  iPadPro: require('images/iPadPro.png'),
  truckIcon: require('images/truck-icon.svg'),
  dollarIcon: require('images/dollar-icon.svg'),
  carIcon: require('images/car-icon.svg'),
  driverSafetyIcon: require('images/driver-safety-icon.svg'),
  accidentsIcon: require('images/accidents-icon.svg'),
  productivityIcon: require('images/productivity-icon.svg'),
  utilizationIcon: require('images/utilization-icon.svg'),
  analyticsIcon: require('images/analytics-icon.svg'),
  insuranceIcon: require('images/insurance-icon.svg'),
  rrightArrow: require('images/right-arrow.svg'),
  floorPlanner: `${BUCKET_PREFIX}/floor-planner.mp4`,
  furnishingDesign: `${BUCKET_PREFIX}/furnishing-design.mp4`,
  texturesColors: `${BUCKET_PREFIX}/textures-colors.mp4`,
  lightFinishes: `${BUCKET_PREFIX}/light-finishes.mp4`,
  desktopScreen: require('images/desktop-screen.png'),
  renderingNew: require('images/rendering-new.png'),
  renderingOld: require('images/rendering-old.png'),
  danJoldzic: require('images/dan-joldzic.png'),
  danJoldzic2x: require('images/dan-joldzic@2x.png'),
  scalabilityIcon: require('images/scalability-icon.svg'),
  optimizationIcon: require('images/optimization-icon.svg'),
  asynchronousIcon: require('images/asynchronous-icon.svg'),
  decoraStudioVideo: `${BUCKET_PREFIX}/decora-studio.mp4`,
  appleWatchIcon: require('images/apple-watch-icon.svg'),
  exerciseIcon: require('images/exercise-icon.svg'),
  trackingIcon: require('images/tracking-icon.svg'),
  fitnessIcon: require('images/fitness-icon.svg'),
  quoteIcon: require('images/quote-icon.svg'),
  teamMembersIcon: require('images/team-members-icon.svg'),
  establishedIcon: require('images/established-icon.svg'),
  xcodeIcon: require('images/xcode-icon.svg'),
  cleanerIcon: require('images/cleaner-icon.svg'),
  startupTimeIcon: require('images/startup-time-icon.svg'),
  compilationTimeIcon: require('images/compilation-time-icon.svg'),
  ihealthMobileAppsGif: require('images/ihealth-mobile-apps.gif'),
  fitcomeAppSecBg: require('images/fitcomeAppSecBg.svg'),
  ihealthAppSecBg: require('images/ihealthAppSecBg.svg'),
  Loader: require('images/loadGif.gif'),
  waypointEesultScreen: require('images/waypoint-result-screen.png'),
  waypointEesultScreen2x: require('images/waypoint-result-screen@2x.png'),
  fillInRxAppScreen: require('images/fill-in-rx-app-screen.png'),
  fillInRxAppScreen2x: require('images/fill-in-rx-app-screen@2x.png'),
  onaDatingBg: require('images/ona-dating-bg.svg'),
  careersBanner: require('images/careers-banner-img.svg'),
  latestPracticesIcon: require('images/latest-practices-icon.svg'),
  horizontalsAgnostic: require('images/horizontals-agnostic.svg'),
  agileFlexibleTeams: require('images/agile-flexible-teams.svg'),
  listTickIcon: require('images/list-tick-icon.svg'),
  libraryIcon: require('images/library-icon.svg'),
  homelyLunchIcon: require('images/homely-lunch-icon.svg'),
  healthInsuranceIcon: require('images/health-insurance-icon.svg'),
  facilitiesIcon: require('images/facilities-icon.svg'),
  flexibleTimingIcon: require('images/flexible-timing-icon.svg'),
  workFormHomeIcon: require('images/work-form-home-icon.svg'),
  careersHiringSecBg: require('images/careershiringsec-bg.png'),
  teamValueBg2x: require('images/team-value-bg@2x.jpg'),
  opportunityCard: require('images/opportunity-card.svg'),
  growthPathCard: require('images/growth-path-card.svg'),
  mattersCard: require('images/matters-card.svg'),
  collaborativeCard: require('images/collaborative-card.svg'),
  ownershipCard: require('images/ownership-card.svg'),
  ratingIcon: require('images/rating-icon.svg'),
  certifiedIcon: require('images/certified-icon.svg'),
  yearIcon: require('images/year-icon.svg'),
  teamSizeIcon: require('images/team-size-icon.svg'),
  ageIcon: require('images/age-icon.svg'),
  linkedinSocialIcon: require('images/linkedin-social-icon.svg'),
  careersBannerLeptop: require('images/careers-banner-leptop.svg'),
  footDropdownIcon: require('images/foot-dropdown-icon.svg'),
  badge: require('images/badge.svg'),
  fullScreen: require('images/full-screen.svg'),
  yellowLgQuote: require('images/yellow-lg-quote.svg'),
  craypayShippingAmountTicket: require('images/craypay-shipping-amount-ticket.png'),
  craypayShippingAmountTicket2x: require('images/craypay-shipping-amount-ticket@2x.png'),
  craypayRetailStoresImg1: require('images/craypay-retail-stores-img1.png'),
  craypayRetailStoresImg12x: require('images/craypay-retail-stores-img1@2x.png'),
  craypayRetailStoresImg2: require('images/craypay-retail-stores-img2.png'),
  craypayRetailStoresImg22x: require('images/craypay-retail-stores-img2@2x.png'),
  craypayRetailStoresImg3: require('images/craypay-retail-stores-img3.png'),
  craypayRetailStoresImg32x: require('images/craypay-retail-stores-img3@2x.png'),
  craypayConstraintSeamlessBg: require('images/craypay-constraint-seamless-bg.png'),
  craypayCircleBg: require('images/craypay-circle-bg.svg'),
  ftsBannerCircle: require('images/fts-banner-circle.svg'),
  ftsBannerTruckImg: require('images/fts-banner-truck-img.png'),
  ftsBannerFoodImg1: require('images/fts-banner-food-img1.png'),
  ftsBannerFoodImg2: require('images/fts-banner-food-img2.png'),
  ftsBannerFoodImg3: require('images/fts-banner-food-img3.png'),
  ftsBannerFoodImg4: require('images/fts-banner-food-img4.png'),
  ftsDevDepIcon: require('images/fts-dev-dep-icon.svg'),
  ftsResponsiveWebIcon: require('images/fts-responsive-web-icon.svg'),
  ftsOfflineIcon: require('images/fts-offline-icon.svg'),
  ftsAdvantageBg: require('images/fts-advantage-bg.svg'),
  ftsChallengesImg1: require('images/fts-challenges-img1.png'),
  ftsChallengesImg12x: require('images/fts-challenges-img1@2x.png'),
  ftsChallengesImg2: require('images/fts-challenges-img2.png'),
  ftsChallengesImg22x: require('images/fts-challenges-img2@2x.png'),
  ftsChallengesImg3: require('images/fts-challenges-img3.png'),
  ftsChallengesImg32x: require('images/fts-challenges-img3@2x.png'),
  apiPaymentGateways: require('images/api-payment-gateways-icon.svg'),
  apiShippingGateway: require('images/api-shipping-gateway-icon.svg'),
  apiSmsGateway: require('images/api-sms-gateway-icon.svg'),
  apiSocialNetwork: require('images/api-social-network-icon.svg'),
  rounndrIntroMobileScreen: require('images/rounndr-intro-mobile-screen.png'),
  roundrIntroHomeImg: require('images/roundr-intro-home-img.png'),
  rounderIntroAddBtn: require('images/rounder-intro-add-btn.png'),
  rounderIntroLinkBtn: require('images/roundr-intro-link-btn.png'),
  phpIcon: require('images/php-icon.svg'),
  mySqlIcon: require('images/my-sql-icon.svg'),
  digitaloceanIcon: require('images/digitalocean-icon.svg'),
  electronLogo: require('images/electron-logo.svg'),
  lgQuoteGray: require('images/lg-quote-gray.svg'),
  roundrResultHomeTop: require('images/roundr-result-home-top.png'),
  rounderScreensAnimation: `${BUCKET_PREFIX}/rounder-screens-animation.mp4`,
  acIntroHomeScreen: require('images/ac-intro-home-screen.png'),
  acIntroElasticksearch: require('images/ac-intro-elasticksearch.png'),
  acIntroConnect: require('images/ac-intro-connect.svg'),
  acintrobucket: require('images/ac-intro-bucket.svg'),
  leftMenuArrow: require('images/left-menu-arrow.svg'),
  compAsmtPlanning: require('images/comp-asmt-planning.png'),
  IacinfrasManagement: require('images/iac-infras-management.png'),
  continuousIntegration: require('images/continuous-integration.png'),
  continuousDeployment: require('images/continuous-deployment.png'),
  strongerSecurity: require('images/stronger-security.png'),
  resourcePlanning: require('images/resource-planning.png'),
  awsLogo: require('images/aws-logo.svg'),
  privacyPolicy: require('images/privacy-policy.pdf'),
  heartIcon: require('images/heart-icon.svg'),
  visiblyCaseLogo2x: require('images/visibly-case-logo@2x.png'),
  freewireCaseLogo2x: require('images/freewire-case-logo@2x.png'),
  fihCaseLogo2x: require('images/flh-case-logo@2x.png'),
  craypayCaseLogo2x: require('images/craypay-case-logo@2x.png'),
  sgFlexibleIcon: require('images/sg-flexible-icon.svg'),
  sgTeamsIcon: require('images/sg-teams-icon.svg'),
  sgLongTermIcon: require('images/sg-long-term-icon.svg'),
  sgVideoLeftPattern: require('images/sg-video-left-pattern.svg'),
  sgVideoRightPattern: require('images/sg-video-right-pattern.svg'),
  serverlessMicon: require('images/serverless-micon.svg'),
  cloudMigrationMicon: require('images/cloud-migration-micon.svg'),
  devopsConsultingMicon: require('images/devops-consulting-micon.svg'),
  smarterItOutsourcingMicon: require('images/smarter-it-outsourcing-micon.svg'),
  biDataEngineeringMicon: require('images/bi-data-engineering-micon.svg'),
  locarbannerCircle: require('images/locar-banner-circle.svg'),
  locarmobileScreen: require('images/locar-mobilescreen-image.png'),
  locarlanguageBox: require('images/locar-languagebox-image.svg'),
  locarNoInternet: require('images/locar-no-internet-image.svg'),
  locarRefresh: require('images/locar-refresh-image.svg'),
  locarupdateBox: require('images/locar-updatebox-image.svg'),
  locarTexiImage: require('images/locar-texi-image.svg'),
  carWheel: require('images/car-wheel.svg'),
  locarRoad: require('images/locar-road.svg'),
  bookRideImgone: require('images/locar-bookride-image-one.png'),
  bookRideImgone2x: require('images/locar-bookride-image-one@2x.png'),
  bookRideImgtwo: require('images/locar-bookride-image-two.png'),
  bookRideImgtwo2x: require('images/locar-bookride-image-two@2x.png'),
  bookRideImgthree: require('images/locar-bookride-image-three.png'),
  bookRideImgthree2x: require('images/locar-bookride-image-three@2x.png'),
  bookRideVideo: `${BUCKET_PREFIX}/locar-book-ride-video.mp4`,
  locarScreenIntroRow: require('images/locar-screen-intro-row.svg'),
  locarMapAnimation: `${BUCKET_PREFIX}/locar-map-animation.mp4`,
  locarResultLeptopscreen: require('images/gocabs-result-screen.png'),
  locarResultLeptopscreen2x: require('images/gocabs-result-screen@2x.png'),
  cultureCraftedBgImage: require('images/culture-crafted-bg-image@2x.png'),
  gretplaceToWorkLogo: require('images/gretplace-to-work-logo.png'),
  glassdoorLogo: require('images/glassdoor-logo.png'),
  transTmsCsLogo2x: require('images/trans-tms-cs-logo@2x.png'),
  missionRabiesCsLogo2x: require('images/mission-rabies-cs-logo@2x.png'),
  stubbsWoottonCsLogo2x: require('images/stubbs-wootton-cs-logo@2x.png'),
  redbullCsLogo2x: require('images/redbull-cs-logo@2x.png'),
  trygCsLogo2x: require('images/tryg-cs-logo@2x.png'),
  moreCaseStudiesCardBg: require('images/more-case-studies-card-bg.png'),
  renessasCsLogo2x: require('images/renessas-cs-logo@2x.png'),
  newtonCsLogo2x: require('images/newton-cs-logo@2x.png'),
  sabreCsLogo2x: require('images/sabre-cs-logo@2x.png'),
  cameoLogo2x: require('images/cameo-logo@2x.png'),
  accentLogo2x: require('images/accent-logo@2x.png'),
  mrIntroScreen: require('images/mr-intro-screen.png'),
  mrIntropathtracker: require('images/mr-pathtracker-img.png'),
  mrIntroMMsdImg: require('images/mr-intro-msd-img.svg'),
  mrIntroCdcImg: require('images/mr-intro-cdc-img.svg'),
  mrIntroVideo: `${BUCKET_PREFIX}/mr-intro-video.mp4`,
  mrResultVideo: `${BUCKET_PREFIX}/mr-result-video.mp4`,
  mrMapAnimationScreen: `${BUCKET_PREFIX}/mr-map-animation-screen.mp4`,
  mrMicrochipsBg: require('images/mr-microchips-bg.png'),
  transTMSLaptopScreen: require('images/trans-tms-laptop-screen.png'),
  transTMSactionBtn: require('images/transtms-action-btn.svg'),
  transTMSRotateIcon: require('images/transtms-rotate-icon.svg'),
  transTMSContainerImg: require('images/transtms-container-image.png'),
  transTMSResultBg: require('images/transtms-result-bg.jpg'),
  invoicingIcon: require('images/invoicing-icon.svg'),
  intraEmailIcon: require('images/intra-email-icon.svg'),
  carrierTrackingUpdatesIcon: require('images/carrier-tracking-updates-icon.svg'),
  carrierTrackingcallicon: require('images/carrier-tracking-call-icon.svg'),
  carrierDispatchIcon: require('images/carrier-dispatch-icon.svg'),
  customerQuoteIcon: require('images/customer-quote-icon.svg'),
  awsSaasIcon: require('images/aws-saas-icon.svg'),
  iosSwift: require('images/ios-swift.svg'),
  kotlinlangLogo: require('images/kotlinlang-logo.svg'),
  bitriseioLogo: require('images/bitriseio-logo.svg'),
  socketioLogo: require('images/socketio-logo.svg'),
  knockoutLogo: require('images/knockout-logo.svg'),
  reduxSagaLogo: require('images/redux-saga-logo.svg'),
  storyBookLogo: require('images/storybook-logo.svg'),
  nextjsLogo: require('images/nextjs-logo.svg'),
  graphqlLogo: require('images/graphql-logo.svg'),
  RxSwiftLogo: require('images/rx-swift.svg'),
  mauticLogo: require('images/mautic-logo.svg'),
  elasticCacheLogo: require('images/elastic-cache-logo.svg'),
  terraformioLogo: require('images/terraformio-logo.svg'),
  devopsLogo: require('images/devops-logo.svg'),
  artificialIntelligenceLogo: require('images/artificial-intelligence-logo.svg'),
  blockchainLogo: require('images/blockchain-logo.svg'),
  mlLogo: require('images/ml-logo.svg'),
  mixpanelLogo: require('images/mixpanel-logo.svg'),
  powerbiLogo: require('images/powerbi-logo.svg'),
  tableauLogo: require('images/tableau-logo.svg'),
  headlessLogo: require('images/headless-logo.svg'),
  awsLogoNew: require('images/aws-logo.png'),
  calendarIcon: require('images/calendar-icon.svg'),
  clockIcon: require('images/clock-icon.svg'),
  iconSecureResilient: require('images/icon-secure-resilient.svg'),
  csAmericanPsychologicalAssociationLogo: require('images/cs-american-psychological-association-logo.svg'),
  csLarvolLogo: require('images/cs-larvol-logo.svg'),
  csMindfulmeLogo: require('images/cs-mindfulme-logo.svg'),
  csSolvpathLogo: require('images/cs-solvpath-logo.svg'),
  ciscoLogo: require('images/cisco-logo.svg'),
  kirloskarBrothersLogo: require('images/kirloskar-brothers-logo.svg'),
  hiltonLogo: require('images/hilton-logo.svg'),
  fihCLogo: require('images/fih-c-logo.svg'),
  trygCLogo: require('images/tryg-logo.svg'),
  cameoCLogo: require('images/cameo-logo.svg'),
  bankOfAmericaLogo: require('images/bank-of-america-logo.svg'),
  mitsubishiLogo: require('images/mitsubishi-logo.svg'),
  hyundaiGlovisLogo: require('images/hyundai-glovis-logo.svg'),
  renesasLogo: require('images/renesas-logo.svg'),
  fujiFilmLogo: require('images/fuji-film-logo.svg'),
  appModernizationMicon: require('images/app-modernization-micon.svg'),
  applicationDevMicon: require('images/application-dev-micon.svg'),
  architectureReviewsMicon: require('images/architecture-reviews-micon.svg'),
  bigDataAnalyticsMicon: require('images/big-data-analytics-micon.svg'),
  cloudAdvisoryServicesMicon: require('images/cloud-advisory-services-micon.svg'),
  customSoftwareDevMicon: require('images/custom-software-dev-micon.svg'),
  dataEngineeringMicon: require('images/data-engineering-micon.svg'),
  dataScienceMicon: require('images/data-science-micon.svg'),
  devopsEngineeringMicon: require('images/devops-engineering-micon.svg'),
  genAIMicon: require('images/gen-aI-micon.svg'),
  machineLearningMicon: require('images/machine-learning-micon.svg'),
  migrationModernizationMicon: require('images/migration-modernization-micon.svg'),
  mlOpsMicon: require('images/ml-ops-micon.svg'),
  performanceEngTestingMicon: require('images/performance-eng-testing-micon.svg'),
  productEngDevMicon: require('images/product-eng-dev-micon.svg'),
  productStrategyMicon: require('images/product-strategy-micon.svg'),
  qaEngineeringMicon: require('images/qa-engineering-micon.svg'),
  bfsiMicon: require('images/bfsi-micon.svg'),
  healthcareMicon: require('images/healthcare-micon.svg'),
  retailMicon: require('images/retail-micon.svg'),
  supplychainMicon: require('images/supplychain-micon.svg'),
  aimlInsightsMicon: require('images/aiml-insights-micon.svg'),
  productEngInsightsMicon: require('images/product-eng-insights-micon.svg'),
  cloudEngInsightsMicon: require('images/cloud-eng-insights-micon.svg'),
  blogNMicon: require('images/blog-micon.svg'),
  ebooksNMicon: require('images/ebooks-micon.svg'),
  newsNMicon: require('images/news-micon.svg'),
  serviceArrowIcon: require('images/service-arrow-icon.svg'),
  platformEngSicon: require('images/platform-engineering-sicon.svg'),
  cloudEngSicon: require('images/cloud-engineering-sicon.svg'),
  aimlEngSicon: require('images/aiml-eng-sicon.svg'),
  digitalExperienceEngSicon: require('images/digital-experience-eng-sicon.svg'),
  mspDataAiAzure2x: require('images/msp-data-ai-azure@2x.png'),
  mspDigitalAppInnovationAzure: require('images/msp-digital-app-innovation-azure.png'),
  mspInfrastructureAzure: require('images/msp-infrastructure-azure@2x.png'),
  databricksLogo: require('images/databricks-logo@2x.png'),
  partnerPremierTierServices: require('images/partner-premier-tier-services@2x.png'),
  cmmiLevel3: require('images/cmmi-level-3.png'),
  theAmericanBusinessAwards: require('images/the-american-business-awards@2x.png'),
  globalOutsourcing100: require('images/global-outsourcing-100@2x.png'),
  greatPlaceToWork: require('images/great-place-to-work@2x.png'),
  glassdoorLogo2x: require('images/glass-door-logo.png'),
  FIHGray: require('images/fih-logo-gray.svg'),
  FujiFilmGray: require('images/fujifilm-gray-logo.svg'),
  HiltonGray: require('images/hilton-gray-logo.svg'),
  Hyundai_Glovis_logoGray: require('images/hyundai-glovis-logo-gray.svg'),
  kirloskarlogoGray: require('images/kirloskar-gray-logo.svg'),
  TrygGray: require('images/tryg-gray-logo.svg'),
  renesaslogoGray: require('images/renesas-gray-logo.svg'),
  mitsubishilogoGray: require('images/mitsubishi-e-logo-gray.svg'),
  foot_G2_logo: require('images/foot-g2-rating-logo.svg'),
  cloudCertifiedIcon: require('images/cloudCertified-icon.svg'),
  bluePeople: require('images/bluePeople-icon.svg'),
  redFlagIcon: require('images/flag-icon.svg'),
  skyscapperIcon: require('images/skyscapper-icon.svg'),
  excellenceImage: require('images/excellence-image.png'),
  clutchImage: require('images/clutch-image.png'),
  g2Image: require('images/g2-image.png'),
}
