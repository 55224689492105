import { EXTERNAL_LINK, isWindowDefined } from 'config/utils'
import React, { Component } from 'react'
import { ButtonStyle, LinkBtnStyle } from './Button.styles'

class Button extends Component {
  render() {
    return (
      <>
        {!this.props.targetBlank ? (
          <>
            {this.props.type !== EXTERNAL_LINK ? (
              <ButtonStyle
                onMouseOver={this.props.onMouseOver}
                onMouseOut={this.props.onMouseOut}
                className={`button ${
                  this.props.bgFillDark ? 'bgFillDark' : ''
                }`}
                to={this.props.buttonLink || '/'}
              >
                <span>{this.props.buttonName}</span>
              </ButtonStyle>
            ) : (
              <LinkBtnStyle
                onMouseOver={this.props.onMouseOver}
                onMouseOut={this.props.onMouseOut}
                className={`button ${
                  this.props.bgFillDark ? 'bgFillDark' : ''
                }`}
                href={`${isWindowDefined ? window.location.origin : ''}${
                  this.props.buttonLink
                }`}
              >
                <span>{this.props.buttonName}</span>
              </LinkBtnStyle>
            )}
          </>
        ) : (
          <LinkBtnStyle
            onMouseOver={this.props.onMouseOver}
            onMouseOut={this.props.onMouseOut}
            className={`button ${this.props.bgFillDark ? 'bgFillDark' : ''}`}
            href={this.props.buttonLink}
            target="_blank"
          >
            <span>{this.props.buttonName}</span>
          </LinkBtnStyle>
        )}
      </>
    )
  }
}

export default Button
