import React, { Component, createRef } from 'react'
import { Link } from 'gatsby'
import { MobileMenuStyle } from './MobileMenu.styles'
import Logo from 'components/Logo/Logo'
import { EXTERNAL_LINK, isWindowDefined, fetchItem } from 'config/utils'
import { navProps, navPropsIn, topMenuData } from 'components/Props/menu-props'

const ExpandIcon = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00033 0.666016L6.00033 4.66602L10.0003 0.666016L11.3337 1.99935L6.00033 7.33268L0.666992 1.99935L2.00033 0.666016Z"
        fill="currentColor"
      />
    </svg>
  )
}

class MobileMainMenuLists extends React.Component {
  state = {
    activeSubpanel: 'Services',
    subActiveSubpanel: 'Services',
  }
  handleClick = activeSubpanel => {
    this.setState({ activeSubpanel })
  }
  handleSubClick = subActiveSubpanel => {
    this.setState({ subActiveSubpanel })
  }
  render() {
    const { activeSubpanel, subActiveSubpanel } = this.state
    return (
      <ul className="menu-nav">
        {this.props.navProps.map((data, i) => {
          return (
            <li
              key={`submenuitem${i}`}
              className={`has-submenu ${
                activeSubpanel === data.navTitle ? 'active' : ''
              }`}
            >
              {activeSubpanel === data.navTitle || !data.navSubmenu ? (
                <>
                  {data.type !== EXTERNAL_LINK ? (
                    <Link
                      className="link-text"
                      activeClassName="active"
                      to={data.navLink}
                    >
                      <span className="nav-title">{data.navTitle}</span>
                      {data.info && (
                        <span className="nav-info"> {data.info}</span>
                      )}
                    </Link>
                  ) : (
                    <a
                      className="link-text"
                      href={`${isWindowDefined ? window.location.origin : ''}${
                        data.navLink
                      }`}
                    >
                      <span className="nav-title">{data.navTitle}</span>
                      {data.info && (
                        <span className="nav-info">{data.info}</span>
                      )}
                    </a>
                  )}
                </>
              ) : (
                <div
                  className="link-text"
                  onClick={() =>
                    this.handleClick(
                      activeSubpanel === data.navTitle ? '' : data.navTitle
                    )
                  }
                >
                  <span className="nav-title">{data.navTitle}</span>
                  {data.info && <span className="nav-info">{data.info}</span>}
                </div>
              )}
              {data.navSubmenu && (
                <div
                  className="expand-icon"
                  onClick={() =>
                    this.handleClick(
                      activeSubpanel === data.navTitle ? '' : data.navTitle
                    )
                  }
                >
                  <ExpandIcon />
                </div>
              )}
              {data.navSubmenu && (
                <ul className="sub-menu-list">
                  {data.navSubmenu.map((subdata, j) => {
                    return (
                      <li
                        key={`subdata${j}`}
                        className={`has-submenu ${
                          subActiveSubpanel === subdata.navTitle ? 'active' : ''
                        }`}
                      >
                        {subActiveSubpanel === subdata.navTitle ||
                        !subdata.navSubmenu ||
                        !subdata.navDetails ? (
                          <>
                            {subdata.type !== EXTERNAL_LINK ? (
                              <Link
                                className="link-text"
                                activeClassName="active"
                                to={subdata.navLink}
                              >
                                <span className="nav-title">
                                  {subdata.navTitle}
                                </span>
                                {subdata.info && (
                                  <span className="nav-info">
                                    {' '}
                                    {subdata.info}
                                  </span>
                                )}
                              </Link>
                            ) : (
                              <a
                                className="link-text"
                                href={`${
                                  isWindowDefined ? window.location.origin : ''
                                }${subdata.navLink}`}
                              >
                                <span className="nav-title">
                                  {subdata.navTitle}
                                </span>
                                {subdata.info && (
                                  <span className="nav-info">
                                    {subdata.info}
                                  </span>
                                )}
                              </a>
                            )}
                          </>
                        ) : (
                          <div
                            className="link-text"
                            onClick={() =>
                              this.handleSubClick(
                                subActiveSubpanel === subdata.navTitle
                                  ? ''
                                  : subdata.navTitle
                              )
                            }
                          >
                            <span className="nav-title">
                              {subdata.navTitle}
                            </span>
                            {subdata.info && (
                              <span className="nav-info">{subdata.info}</span>
                            )}
                          </div>
                        )}
                        {subdata.navSubmenu || subdata.navDetails ? (
                          <div
                            className="expand-icon"
                            onClick={() =>
                              this.handleSubClick(
                                subActiveSubpanel === subdata.navTitle
                                  ? ''
                                  : subdata.navTitle
                              )
                            }
                          >
                            <ExpandIcon />
                          </div>
                        ) : (
                          ' '
                        )}
                        {subdata.navSubmenu ? (
                          <ul className="sub-menu-list">
                            {subdata.navSubmenu.map((subchilddata, k) => {
                              return (
                                <li key={`subchilddata${k}`}>
                                  {subchilddata.type !== EXTERNAL_LINK ? (
                                    <Link
                                      className="link-text"
                                      activeClassName="active"
                                      to={subchilddata.navLink}
                                    >
                                      <span className="nav-title">
                                        {subchilddata.navTitle}
                                      </span>
                                      {subchilddata.info && (
                                        <span className="nav-info">
                                          {' '}
                                          {subchilddata.info}
                                        </span>
                                      )}
                                    </Link>
                                  ) : (
                                    <a
                                      className="link-text"
                                      href={`${
                                        isWindowDefined
                                          ? window.location.origin
                                          : ''
                                      }${subchilddata.navLink}`}
                                    >
                                      <span className="nav-title">
                                        {subchilddata.navTitle}
                                      </span>
                                      {subchilddata.info && (
                                        <span className="nav-info">
                                          {subchilddata.info}
                                        </span>
                                      )}
                                    </a>
                                  )}
                                </li>
                              )
                            })}
                          </ul>
                        ) : (
                          <ul className="sub-menu-list">
                            <li>
                              {subdata.type !== EXTERNAL_LINK ? (
                                <Link
                                  className="link-text"
                                  activeClassName="active"
                                  to={subdata.navLink}
                                >
                                  <span className="nav-title">
                                    {subdata.navTitle}
                                  </span>
                                  {subdata.navDetails && (
                                    <span className="nav-info">
                                      {subdata.navDetails}
                                    </span>
                                  )}
                                </Link>
                              ) : (
                                <a
                                  className="link-text"
                                  href={`${
                                    isWindowDefined
                                      ? window.location.origin
                                      : ''
                                  }${subdata.navLink}`}
                                >
                                  <span className="nav-title">
                                    {subdata.navTitle}
                                  </span>
                                  {subdata.navDetails && (
                                    <span className="nav-info">
                                      {subdata.navDetails}
                                    </span>
                                  )}
                                </a>
                              )}
                            </li>
                          </ul>
                        )}
                      </li>
                    )
                  })}
                </ul>
              )}
            </li>
          )
        })}
      </ul>
    )
  }
}

class MobileMenu extends Component {
  state = {
    isActive: null,
    activeSubpanel: 'Services',
  }
  constructor(props) {
    super(props)

    this.mobileMenuContentRef = createRef()
  }

  menuEvent = () => {
    this.setState({ isActive: !this.state.isActive }, () => {
      if (this.state.isActive) {
        document.body.classList.add('over-hidden')
      } else {
        document.body.classList.remove('over-hidden')
      }
    })
  }
  outsideMenuEvent = () => {
    if (
      this.mobileMenuContentRef.current &&
      !this.mobileMenuContentRef.current.contains(event.target)
    ) {
      this.setState({ isActive: !this.state.isActive }, () => {
        if (this.state.isActive) {
          document.body.classList.add('over-hidden')
        } else {
          document.body.classList.remove('over-hidden')
        }
      })
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('over-hidden')
  }

  // showMenu = () => {
  //   if (fetchItem('country') === 'IN' || fetchItem('country') === null) {
  //     return <MobileMainMenuLists navProps={navPropsIn} />
  //   } else {
  //     return <MobileMainMenuLists navProps={navProps} />
  //   }
  // }
  render() {
    const { isActive } = this.state
    const obj = {
      null: '',
      true: 'menu-open',
      false: 'menu-close',
    }

    return (
      <MobileMenuStyle className={`mobile-menu ${obj[isActive]} `}>
        <div className="menu-btn" onClick={this.menuEvent}>
          <div className="menu-icon">
            <div className="top-line" />
            <div className="middle-line" />
            <div className="bottom-line" />
          </div>
          <div className="title">Menu</div>
        </div>
        <div className="mobilemenu" onClick={this.outsideMenuEvent}>
          <div className="mobile-menu-content" ref={this.mobileMenuContentRef}>
            <div className="menu-head">
              <Logo />
              <div className="close-btn" onClick={this.menuEvent} />
            </div>
            <div className="menu-group">
              <MobileMainMenuLists navProps={navProps} />
            </div>
          </div>
        </div>
      </MobileMenuStyle>
    )
  }
}

export default MobileMenu
