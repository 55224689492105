import { Link } from 'gatsby'
import { EXTERNAL_LINK, isWindowDefined } from 'config/utils'

import React from 'react'

const NavLink = props => {
  return (
    <>
      {props.linkType !== EXTERNAL_LINK ? (
        <Link
          activeClassName="active"
          to={props.navLink}
          className={props.className}
        >
          {props.children}
        </Link>
      ) : (
        <a
          href={`${isWindowDefined ? window.location.origin : ''}${
            props.navLink
          }`}
          className={props.className}
        >
          {props.children}
        </a>
      )}
    </>
  )
}

export default NavLink
