import React, { useState, useEffect, useRef } from 'react'
// import ReCAPTCHA from 'react-google-recaptcha'
import { navigate } from 'gatsby'
import moment from 'moment'
import { ContactStepFormStyle } from './ContactStepForm.styles'
import loadable from '@loadable/component'
import InputRange from 'react-input-range'
import { images } from 'config/images'
import ReactWeeklyDayPicker from 'components/DayPicker/DayPicker'
import {
  addPipeDrivePerson,
  addPipeDriveDeal,
  addPipeDriveDealFile,
  fetchKeywords,
  sendMail,
  uploadFile,
  saveContactUs,
} from 'config/pipedrive'
import {
  windowWidth,
  formFields,
  workingOnValues,
  maxBudget,
  minBudget,
  workingOnOptions,
  timezones,
  appendToSite,
  ContactUsSchema,
  timeSlots,
  MAX_FILE_SIZE,
  formNames,
  budgets,
} from './contactFormConstants'
import {
  isWindowDefined,
  formTypes,
  sendFormSuccessGAEvent,
  logMessage,
} from 'config/utils'
const queryString = require('query-string')

const FormikWrapper = loadable.lib(() => import('formik'))

const ContactStepForm = props => {
  const inputRef = useRef()
  // const recaptchaRef = useRef()
  const formRef = useRef()

  const [mobilView, setMobilView] = useState(false)
  const [file, setFile] = useState(null)
  const [applicationType, setApplicationType] = useState(null)
  const [startDay, setStartDay] = useState(moment())
  const [isFileValid, setValidFile] = useState(true)
  // const [isCaptchaValid, setValidCaptcha] = useState(false)

  const checkFileValidity = file => {
    if (file.size > MAX_FILE_SIZE) {
      setValidFile(false)
    } else {
      setValidFile(true)
    }
    setFile(file)
  }

  // const onCaptchaChange = value => {
  //   console.log({ value })
  //   if (value) {
  //     setValidCaptcha(true)
  //     formRef.current.handleSubmit()
  //   }
  // }

  // const submitForm = async () => {
  //   recaptchaRef.current.execute()
  // }

  useEffect(() => {
    if (isWindowDefined && window.innerWidth < windowWidth) {
      setMobilView(true)
    }
    if (props.location.search) {
      let parse = queryString.parse(props.location.search)
      if (parse.type) {
        setApplicationType(parse.type)
      }
    }
  }, [])
  return (
    <ContactStepFormStyle>
      <FormikWrapper>
        {({ Formik }) => (
          <Formik
            validationSchema={ContactUsSchema}
            ref={formRef}
            initialValues={{
              [formNames.name]: '',
              [formNames.email]: '',
              [formNames.phone]: '',
              [formNames.website]: '',
              [formNames.reference]: '',
              [formNames.projectDescription]: '',
              // [formNames.budget]: {
              // min: 100000,
              // max: 300000,
              // },
              [formNames.budget]: '',
              // [formNames.interestedIn]: setApplicationType
              // ? { ...workingOnValues, ...{ [applicationType]: true } }
              // : workingOnValues,
              [formNames.timezone]: 'EST',
              [formNames.selectedDay]: [moment()],
              [formNames.timeSlot]: '06:00 AM - 07:00 AM',
            }}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setStatus, resetForm }
            ) => {
              let { selectedDay } = formNames
              try {
                if (window.TriblioAnalyticsObject) {
                  const form = document.querySelector('#contact-form')
                  const data = new FormData(form)
                  const formData = new URLSearchParams(data).toString()
                  const env = {
                    fd: formData,
                  }
                  window.TriblioAnalyticsObject.track(40, env)
                }

                let scheduleDate = new Date(values.selectedDay[0])

                var dd = String(scheduleDate.getDate()).padStart(2, '0')
                var mm = String(scheduleDate.getMonth() + 1).padStart(2, '0') //January is 0!
                var yyyy = scheduleDate.getFullYear()

                scheduleDate = dd + '/' + mm + '/' + yyyy

                const dataObj = {
                  name: values.name,
                  email: values.email,
                  phone: values.phone,
                  website: values.website,
                  description: values.projectDescription,
                  referenceDetails: values.reference,
                  budget: values.budget,
                  callScheduleDate: scheduleDate || '',
                  callScheduleTime: values.timeSlot,
                  callScheduleTimezone: values.timezone,
                }

                if (file) {
                  dataObj['image'] = file.name
                }
                console.log(dataObj)

                if (file) {
                  await uploadFile(file)
                }

                await saveContactUs(dataObj)

                sendFormSuccessGAEvent()
                resetForm()
                navigate('/thank-you/?from=contact-us-form')
              } catch (formError) {
                // logMessage('cannot submit contact page form')
                console.error('cannot submit contact page form')
              } finally {
                setSubmitting(false)
              }
              // } else {
              //   setSubmitting(false)
              //   return
              // }
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              errors,
              touched,
              isSubmitting,
            }) => {
              return (
                <form
                  className="contact-step-form"
                  id="contact-form"
                  onSubmit={handleSubmit}
                >
                  {
                    // <input type="hidden" name="_autopilot_session_id" />
                  }
                  <div className="steps">
                    <div className="title">1. Tell us about your company</div>
                    <div className="input-field-row">
                      <div className="form-field-blk">
                        <input
                          className={`label-input-field ${
                            values[formNames.name].length ? 'fill' : ''
                          } ${errors[formNames.name] ? 'form-error' : ''}`}
                          type="text"
                          onChange={handleChange}
                          value={values[formNames.name]}
                          name={formNames.name}
                          id="cname"
                        />
                        <label htmlFor={formNames.name}>Your name *</label>
                        {errors[formNames.name] && (
                          <span className="error-msg">
                            {errors[formNames.name]}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="input-field-row">
                      <div className="form-field-blk column">
                        <input
                          className={`label-input-field ${
                            values[formNames.email].length ? 'fill' : ''
                          } ${errors[formNames.email] ? 'form-error' : ''}`}
                          type="email"
                          onChange={handleChange}
                          value={values[formNames.email]}
                          name={formNames.email}
                          id="cemail"
                        />
                        <label htmlFor={formNames.email}>Your email *</label>
                        {errors[formNames.email] && (
                          <span className="error-msg">
                            {errors[formNames.email]}
                          </span>
                        )}
                      </div>

                      <div className="form-field-blk column">
                        <input
                          className={`label-input-field ${
                            values[formNames.phone].length ? 'fill' : ''
                          }`}
                          type="tel"
                          onChange={handleChange}
                          value={values[formNames.phone]}
                          name={formNames.phone}
                          id="cphone"
                        />
                        <label htmlFor={formNames.phone}>
                          Your phone number
                        </label>
                      </div>
                    </div>

                    <div className="input-field-row">
                      <div className="form-field-blk">
                        <input
                          className={`label-input-field ${
                            values[formNames.website].length ? 'fill' : ''
                          }`}
                          type="url"
                          onChange={e =>
                            setFieldValue(
                              formNames.website,
                              appendToSite(e.target.value)
                            )
                          }
                          value={values[formNames.website]}
                          name={formNames.website}
                          id="cwebsite"
                        />
                        <label htmlFor={formNames.website}>
                          Company Name or Website
                        </label>
                      </div>
                    </div>
                    {/* <div className="input-field-row">
                      <div className="form-field-blk">
                        <input
                          className={`label-input-field ${values[formNames.challenge].length ? 'fill' : ''
                            }`}
                          type="text"
                          onChange={handleChange}
                          value={values[formNames.challenge]}
                          name={formNames.challenge}
                          id="cchallenge"
                        />
                        <label htmlFor={formNames.challenge}>
                          What’s your company’s biggest challenge today?
                        </label>
                      </div>
                    </div> */}
                  </div>

                  <div className="steps">
                    <div className="title">
                      2. Tell us more about your project
                    </div>
                    <div className="input-field-row">
                      <div className="form-field-blk">
                        <textarea
                          name={formNames.projectDescription}
                          onChange={handleChange}
                          value={values[formNames.projectDescription]}
                          className={`label-input-field textarea ${
                            values[formNames.projectDescription].length
                              ? 'fill'
                              : ''
                          } ${
                            errors[formNames.projectDescription]
                              ? 'form-error'
                              : ''
                          }`}
                          id="cdescription"
                        />
                        <label htmlFor={formNames.projectDescription}>
                          Describe your project briefly *
                        </label>
                        {errors[formNames.projectDescription] && (
                          <span className="error-msg">
                            {errors[formNames.projectDescription]}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="input-field-row">
                      <div className="form-field-blk">
                        <select
                          name={formNames.budget}
                          id="cbudget"
                          className={`label-input-field fill ${
                            errors[formNames.budget] ? 'form-error' : ''
                          }`}
                          onChange={handleChange}
                          value={values[formNames.budget]}
                        >
                          <option value="" disabled selected>
                            Please Choose the Budget
                          </option>
                          {budgets.map(budget => (
                            <option key={Math.random()} value={budget.value}>
                              {budget.label}
                            </option>
                          ))}
                        </select>
                        <label htmlFor={formNames.budget}>
                          Estimated BUDGET *
                        </label>
                        {errors[formNames.budget] && (
                          <span className="error-msg">
                            {errors[formNames.budget]}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* <div className="input-field-row">
                      <div className="range-input-field">
                        <div className="title-row">
                          <div className="row-title">What’s your budget?</div>
                        </div>
                        <InputRange
                          draggableTrack
                          formatLabel={value => `$${value}`}
                          maxValue={maxBudget}
                          minValue={minBudget}
                          onChange={value =>
                            setFieldValue(formNames.budget, value)
                          }
                          value={values[formNames.budget]}
                          id="cbudget"
                        />
                      </div>
                    </div> */}

                    <div className="input-field-row">
                      <div className="input-file-row">
                        <label
                          htmlFor="project_brief"
                          className={!isFileValid ? 'form-error' : ''}
                        >
                          <div className="file-content">
                            <div className="icon">
                              <img src={images.iconDownload} alt="" />
                            </div>
                            <div className="content-label">
                              {file
                                ? file.name
                                : 'Drag images or browse to upload (optional)'}
                            </div>
                          </div>
                          <div className="row-wrap">
                            {file && (
                              <div
                                onClick={e => {
                                  inputRef.current.value = ''
                                  setFile(null)
                                  setValidFile(true)
                                }}
                                className="btn-close"
                              ></div>
                            )}
                            <div className="button">upload</div>
                          </div>
                        </label>
                        <input
                          className="file-field"
                          type="file"
                          accept="application/msword, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain"
                          onChange={e => checkFileValidity(e.target.files[0])}
                          ref={inputRef}
                          name="project_brief"
                        />
                        {!isFileValid && (
                          <span className="error-msg">
                            Max file size is 10 MB
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="steps">
                    <div className="title">3. Where did you hear about us?</div>
                    <div className="input-field-row">
                      <div className="form-field-blk">
                        <textarea
                          name={formNames.reference}
                          onChange={handleChange}
                          value={values[formNames.reference]}
                          className={`label-input-field textarea ${
                            values[formNames.reference].length ? 'fill' : ''
                          } ${errors[formNames.reference] ? 'form-error' : ''}`}
                          id="creference"
                        />
                        <label htmlFor={formNames.reference}>
                          How did you hear about us?
                        </label>
                        {errors[formNames.reference] && (
                          <span className="error-msg">
                            {errors[formNames.reference]}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* {!applicationType && (
                    <div className="steps">
                      <div className="title">
                        3. What services are you interested in?
                      </div>
                      <div className="input-field-row">
                        <div className="service-listing">
                          {workingOnOptions.map(option => (
                            <div
                              key={option.id}
                              className={`service-col ${option.className}`}
                            >
                              <input
                                type="checkbox"
                                name={`${formNames.interestedIn}.${option.value}`}
                                id={`checkbox${option.id}`}
                                onChange={handleChange}
                                checked={
                                  values[formNames.interestedIn][option.value]
                                }
                              />
                              <label htmlFor={`checkbox${option.id}`}>
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )} */}
                  <div className="steps">
                    <div className="title">
                      {`${
                        applicationType ? '3' : '4'
                      }. Schedule a call with our tech expert. Get a detailed
                      tech consultation for free!`}
                    </div>
                    <div className="date-picker-row">
                      <div className="heading-row">
                        <div className="day">Select a Day</div>
                        <div className="meeting">30 Min Meeting</div>
                      </div>
                      <input
                        className="date-hidden-field"
                        type="text"
                        value={
                          values[formNames.selectedDay][0]._d ||
                          values[formNames.selectedDay][0].split('T')[0]
                        }
                        name="schedule-date"
                      />
                      <ReactWeeklyDayPicker
                        daysCount={7}
                        startDay={startDay}
                        selectDay={days =>
                          setFieldValue(formNames.selectedDay, days)
                        }
                        onNextClick={(startDay, selectedDays) =>
                          setStartDay(startDay)
                        }
                        onPrevClick={(startDay, selectedDays) =>
                          setStartDay(startDay)
                        }
                        mobilView={mobilView}
                        multipleDaySelect={false}
                        hiddens={{
                          weekly: [0, 6],
                        }}
                        selectedDays={values[formNames.selectedDay]}
                        todayText="new"
                        beforeToday={false}
                      />
                    </div>
                    <div className="flex-row">
                      <div className="time-zone">
                        Timezone -
                        <select
                          name={formNames.timezone}
                          id="time"
                          onChange={handleChange}
                          value={values[formNames.timezone]}
                        >
                          {timezones.map(timezone => (
                            <option key={Math.random()} value={timezone.value}>
                              {timezone.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="time-slot">
                        Schedule Meeting-
                        <select
                          onChange={handleChange}
                          name={formNames.timeSlot}
                          value={values[formNames.timeSlot]}
                          id="cmeeting"
                        >
                          {timeSlots.map((slot, i) => (
                            <option key={i} value={slot.value}>
                              {slot.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    onChange={onCaptchaChange}
                    sitekey="6Lf4dMsUAAAAAKb-WhynCVkgAKAuxVjNwkWvIaIa"
                  /> */}

                  <div className="bottomAlert">
                    <div className="submit-row">
                      <button
                        className="btn-submit button"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                      {isSubmitting && <img src={images.Loader} alt="" />}
                    </div>
                    {Object.entries(errors).length > 0 &&
                    errors.constructor === Object ? (
                      <div>
                        <div className="bottomErrorText">
                          Please fill all the required fields
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </form>
              )
            }}
          </Formik>
        )}
      </FormikWrapper>
    </ContactStepFormStyle>
  )
}

export default ContactStepForm
