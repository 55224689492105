import React, { useState } from 'react'
import { Link } from 'gatsby'
import { NavStyle } from './Nav.styles'
import { EXTERNAL_LINK, isWindowDefined } from 'config/utils'
import { navProps } from 'components/Props/menu-props'
import MenuIcon from './MenuIcon'
import { images } from 'config/images'
import NavLink from 'components/NavLink/NavLink'

const SubMenuList = props => {
  return (
    <li>
      <NavLink linkType={props.linkType} navLink={props.navLink}>
        <div className="icon">
          <img src={props.menuIcon} alt="" />
        </div>
        <div className="title">
          <span className="nav-title">{props.navTitle}</span>
        </div>
      </NavLink>
    </li>
  )
}

const SubMenu = props => {
  const [hovered, setHovered] = useState(false)
  const onHover = () => {
    setHovered(true)
    props.getHoverState(true)
  }
  const leaveHover = () => {
    setHovered(false)
    props.getHoverState(false)
  }
  return (
    <li
      className={`mega-menu-list-item ${props.activeClass}  ${
        hovered ? 'openMenu' : ''
      }`}
      onMouseEnter={() => onHover()}
      onMouseLeave={() => leaveHover()}
    >
      <NavLink linkType={props.linkType} navLink={props.navLink}>
        {props.navTitle}{' '}
        <img
          className="menu-arrow-icon"
          src={images.leftMenuArrow}
          alt="icon"
        />
      </NavLink>
      <div className="submenu-list">
        {props.navSubmenuData && (
          <ul>
            {props.navSubmenuData.map((subData, l) => {
              return (
                <SubMenuList
                  key={`${props.keyItem}-${l}`}
                  linkType={subData.type}
                  navLink={subData.navLink}
                  navTitle={subData.navTitle}
                  menuIcon={subData.menuIcon}
                />
              )
            })}
          </ul>
        )}
      </div>
    </li>
  )
}

const Nav = () => {
  const [hoverValue, setHoverValue] = useState(false)
  const getHoverState = value => {
    setHoverValue(value)
  }
  const showMenu = () => {
    return navProps.map((navProp, i) => (
      <li key={i}>
        {navProp.navSubmenu && (
          <div className="submenu">
            <div className="header-container">
              <ul>
                {navProp.navSubmenu.map((submenuProps, j) => (
                  <SubMenu
                    key={`${i}${j}`}
                    keyItem={`${i}${j}`}
                    linkType={submenuProps.type}
                    navLink={submenuProps.navLink}
                    className={submenuProps.className}
                    menuIcon={submenuProps.menuIcon}
                    navTitle={submenuProps.navTitle}
                    info={submenuProps.info}
                    navSubmenuData={submenuProps.navSubmenu}
                    activeClass={
                      hoverValue === false ? submenuProps.activeClass : ''
                    }
                    getHoverState={getHoverState}
                  />
                ))}
              </ul>
            </div>
          </div>
        )}
        <NavLink linkType={navProp.type} navLink={navProp.navLink}>
          {navProp.navTitle} {navProp.navSubmenu && <MenuIcon />}
        </NavLink>
      </li>
    ))
  }

  return (
    <NavStyle className="navigation-menu">
      <ul>{showMenu()}</ul>
    </NavStyle>
  )
}

export default Nav
