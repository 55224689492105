import { isWindowDefined } from './utils'

export const appendChatScript = () => {
  let script = document.createElement('script')

  script.innerHTML = `window.__lc = window.__lc || {};
    window.__lc.license = 11125882;
    (function() {
      var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
      lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
    })();`

  // script.innerHTML = `"use strict"; !function() { var t = window.driftt = window.drift = window.driftt || []; if (!t.init) { if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice.")); t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ], t.factory = function(e) { return function() { var n = Array.prototype.slice.call(arguments); return n.unshift(e), t.push(n), t; }; }, t.methods.forEach(function(e) { t[e] = t.factory(e); }), t.load = function(t) { var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script"); o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js"; var i = document.getElementsByTagName("script")[0]; i.parentNode.insertBefore(o, i); }; } }(); drift.SNIPPET_VERSION = '0.3.1'; drift.load('hrxk7bimg334');`

  // let noscript = document.createElement('noscript')
  // noscript.innerHTML = `<a href="https://www.livechatinc.com/chat-with/11125882/" rel="nofollow">Chat with us</a>,
  //   powered by <a href="https://www.livechatinc.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>`

  document.body.appendChild(script)

  let btnHTML = `<div data-id="VVE0Bpq2VBz" class="livechat_button"><a href="https://www.livechatinc.com/customer-service/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_11125882">5 customer service tips from LiveChat</a></div>`
  let div = document.createElement('div')
  div.innerHTML = btnHTML
  document.body.appendChild(div)
}

export const appendDMCAScript = () => {
  if (isWindowDefined) {
    const script = document.createElement('script')
    script.innerHTML = `(function () {
document.addEventListener("DOMContentLoaded", function () {
    var e = "dmca-badge";
    var t = "refurl";
    var n = document.querySelectorAll('a.'+e);
    if (n[0].getAttribute("href").indexOf("refurl") < 0) {
        for (var r = 0; r < n.length; r++) {
            var i = n[r];
            i.href = i.href + (i.href.indexOf("?") === -1 ? "?" : "&") + t + "=" + document.location
        }
    }
  }, false)
  }
)()`

    document.body.appendChild(script)
  }
}

const scriptsHTML = [
  // twitter pixel
  `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
// Insert Twitter Pixel ID and Standard Event data below
twq('init','o26z8');
twq('track','PageView');`,
  // fb events
  `!function(f,b,e,v,n,t,s)
 {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
 n.callMethod.apply(n,arguments):n.queue.push(arguments)};
 if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
 n.queue=[];t=b.createElement(e);t.async=!0;
 t.src=v;s=b.getElementsByTagName(e)[0];
 s.parentNode.insertBefore(t,s)}(window, document,'script',
 'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '2574243515958654');
 fbq('track', 'PageView');`,
]

const noscriptsHTML = [
  // google tag manager
  `<iframe
  src="https://www.googletagmanager.com/ns.html?id=GTM-P2CFCC3"
  height="0"
  width="0"
  style={{ display: 'none', visibility: 'hidden' }}
  title="https://www.googletagmanager.com/ns.html?id=GTM-P2CFCC3"
></iframe>`,
  // px.ads linkedin
  ` <img
height="1"
width="1"
style={{ display: 'none' }}
alt=""
src="https://px.ads.linkedin.com/collect/?pid=685251&fmt=gif"
/>`,
  `<img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=2574243515958654&ev=PageView&noscript=1"
/>`,
]

const tagScripts = [
  // 'https://www.googletagmanager.com/gtag/js?id=UA-54050564-1',
  'https://api.ontimesales.com/web-tracking/initializer.js',
]

export const appendAnalyticScripts = () => {
  for (let script of scriptsHTML) {
    let elem = document.createElement('script')
    elem.innerHTML = script
    document.body.appendChild(elem)
  }

  for (let script of tagScripts) {
    let elem = document.createElement('script')
    elem.src = script
    document.body.appendChild(elem)
  }

  for (let noscript of noscriptsHTML) {
    let elem = document.createElement('noscript')
    elem.innerHTML = noscript
    document.body.appendChild(elem)
  }
}
