import styled, { keyframes } from 'styled-components'
import {
  lg,
  themeColor,
  headingFont,
  sm,
  bodyFont,
  primaryColor,
  md,
} from 'config/variables'
import { images } from 'config/images'

export const MobileMenuStyle = styled.div`
  display: none;
  ${lg(`
    display: block;
    `)}
  .menu-btn {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 99;
    display: block;
    justify-content: flex-end;
    background-color: transparent;
    cursor: pointer;
    transition: all 300ms ease;
    ${md(`top: 20px;`)}
    ${sm(`top: 18px;`)}
    .menu-icon {
      position: relative;
      display: inline-block;
      width: 22px;
      height: 16px;
      margin-top: 0px;
      padding-top: 0px;
      .top-line {
        position: absolute;
        left: 0px;
        top: 2px;
        right: 0px;
        width: 22px;
        height: 2px;
        margin-bottom: 0px;
        border-radius: 1px;
        background-color: ${themeColor};
      }
      .middle-line {
        position: absolute;
        left: 0px;
        top: 9px;
        right: 0px;
        width: 22px;
        height: 2px;
        margin-bottom: 0px;
        border-radius: 1px;
        background-color: ${themeColor};
      }
      .bottom-line {
        position: absolute;
        left: 0px;
        top: 16px;
        right: 0px;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        background-color: ${themeColor};
      }
    }
    .title {
      display: inline-block;
      margin-left: 9px;
      font-family: ${headingFont};
      color: ${themeColor};
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      letter-spacing: 0.21px;
    }
  }
  .mobilemenu {
    position: fixed;
    left: 0px;
    top: 0px;
    right: auto;
    bottom: auto;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    height: 100vh;
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    .mobile-menu-content {
      position: fixed;
      left: 0%;
      top: 0%;
      right: auto;
      bottom: auto;
      z-index: 1;
      width: 100%;
      max-width: 100%;
      height: 100vh;
      transform: translate(-100%, 0px);
      background: linear-gradient(to top, #fff, #f0efff);
      transition: all 0.3s ease-in-out;
      .menu-head {
        padding: 30px 24px 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .close-btn {
          line-height: 24px;
          background: url(${images.iconClose}) no-repeat center;
          width: 18px;
          height: 18px;
          ${md(`
            font-size: 0;
          `)}
        }
      }
      .menu-head-title {
        padding: 0 14px 25px 24px;
        h3 {
          font-weight: bold;
          font-size: 17px;
          line-height: 22px;
          color: ${primaryColor};
          font-family: ${headingFont};
          margin: 0;
          padding-bottom: 9px;
          border-bottom: 1px solid #e3e3e3;
        }
      }
      .menu-group {
        overflow: auto;
        height: calc(100vh - 87px);
        -webkit-overflow-scrolling: touch;
        .menu-nav {
          margin: 0;
          text-align: left;
          padding: 0 14px 0 24px;
          margin-bottom: 28px;
          overflow: hidden;
          > li {
            list-style: none;
            padding: 12px 0 12px 0;
            font-size: 18px;
            line-height: 23px;
            letter-spacing: 0.5px;
            font-weight: 700;
            font-family: ${headingFont};
            color: ${primaryColor};
            position: relative;
            > .link-text {
              color: ${primaryColor};
              max-width: calc(100% - 20px);
              display: flex;
              flex-direction: column;
              &:hover {
                color: ${themeColor};
              }
              .nav-title {
                font-size: 18px;
                line-height: 23px;
                margin-bottom: 0;
                font-family: ${headingFont};
                font-wight: 700;
                display: block;
              }
              .nav-info {
                font-size: 12px;
                font-weight: 400;
                font-style: normal;
                margin-top: 2px;
                font-family: ${bodyFont};
                margin: 2px 0 0;
                display: block;
              }
            }
            .expand-icon {
              position: absolute;
              right: 0;
              top: 21px;
              width: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: rgba(0, 0, 0, 0.5);
              transform-origin: center;
              z-index: 999;
              cursor: pointer;
              svg {
                transition: all 0.3s ease-in-out;
                width: 100%;
                height: auto;
                display: block;
              }
            }
            .sub-menu-list {
              padding-left: 30px;
              width: 100%;
              transition: all 0.3s ease;
              max-height: 0;
              opacity: 0;
              pointer-events: none;
              li {
                position: relative;
                padding: 10px 0;
                list-style: none;
                &:last-child {
                  padding-bottom: 0px;
                }
                .link-text {
                  color: ${primaryColor};
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 18px;
                  max-width: calc(100% - 20px);
                  display: flex;
                  flex-direction: column;
                  font-family: ${headingFont};
                  &:hover {
                    color: ${themeColor};
                  }
                  .nav-title {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 700;
                    margin-bottom: 0;
                    font-family: ${headingFont};
                    display: block;
                  }
                  .nav-info {
                    font-size: 12px;
                    font-weight: 400;
                    font-style: normal;
                    margin: 2px 0 0;
                    font-family: ${bodyFont};
                    display: block;
                  }
                }
                .expand-icon {
                  top: 17px;
                }
                &.active {
                  > .sub-menu-list {
                    max-height: 1700px;
                    opacity: 1;
                    pointer-events: auto;
                    padding-top: 12px;
                  }
                  > .link-text {
                    color: ${themeColor};
                  }
                }
              }
            }
          }
          li {
            &.active {
              > .expand-icon {
                color: ${themeColor};
                transform: scaleY(-1);
              }
              > .link-text {
                color: ${themeColor};
              }
              > .sub-menu-list {
                max-height: 1700px;
                opacity: 1;
                pointer-events: auto;
                padding-top: 12px;
              }
            }
          }
        }
      }
    }
  }
  &.menu-open {
    .mobilemenu {
      visibility: visible;
      opacity: 1;
      .mobile-menu-content {
        transform: translateX(0);
      }
    }
  }
  &.menu-close {
    .mobilemenu {
      visibility: hidden;
      opacity: 0;
    }
  }
`
