import React, { useEffect } from 'react'
import { HubSpotFooterFormStyle } from './HubSpotFooterForm.styles'

function HubSpotFooterForm(props) {
  const { region, portalId, formId } = props
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/embed/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: `${region}`,
          portalId: `${portalId}`,
          formId: `${formId}`,
          target: '#hubspotForm',
        })
      }
    })
  }, [])

  return <HubSpotFooterFormStyle id="hubspotForm"></HubSpotFooterFormStyle>
}

export default HubSpotFooterForm
