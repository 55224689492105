import React from 'react'
import { images } from 'config/images'

export const ContactProps = {
  contcatAddressProp: {
    heading: (
      <>
        Don’t be shy, <span className="heading-red">say Hi!</span>
      </>
    ),
    addressList: [
      {
        countryName: 'Orlando',
        address: '111 North Orange Avenue Suite 800, Orlando, FL 32801, USA',
        tel: 'Call us now',
        telTag: 'tel:13212372727',
      },
      {
        countryName: 'Dubai',
        address: '1505 Tiffany Towers, Cluster W, Jumeirah Lake Towers, Dubai',
        tel: 'Call us now',
        telTag: 'tel:13212372727',
      },
      {
        countryName: 'Canada',
        address: '15-115 - Bentall II Vancouver BC, V7X 1M8',
        tel: 'Call us now',
        telTag: 'tel:13212372727',
      },
      {
        countryName: 'India',
        address:
          '5th Floor, 501 Binori Bsquare2, Nr Double Tree By Hilton, Ambli - Bopal Rd, Ahmedabad, Gujarat 380058',
        tel: 'Call us now',
        telTag: 'tel:+912717452402',
      },
    ],
    followHeading: 'Follow Us',
    SocialMedia: [
      {
        src: images.iconFacebook,
        alt: 'Facebook',
        URL: '/',
      },
      {
        src: images.iconInstagram,
        alt: 'Instagram',
        URL: '/',
      },
      {
        src: images.iconTwitter,
        alt: 'Twitter',
        URL: '/',
      },
      {
        src: images.iconYoutube,
        alt: 'Youtube',
        URL: '/',
      },
    ],
  },
  contactMainSecProps: {
    contentHeading: 'What will happen next?',
    contentPara: (
      <>
        We will get back to you within 24 hours
        <br />
        <br />
        You are a step closer to building great software
      </>
    ),
    contentList: [
      {
        title: '1. Free technical consultation',
        description:
          'Detailed tech plan includes things like what tech stack to use, tech architecture, timeline, and budget for your project.',
      },
      {
        title: '2. Connect with the tech team',
        description:
          'Over a series of calls, our tech team discusses how different technologies and frameworks will bring your vision to life.',
      },
      {
        title: '3. Onboarding the team',
        description:
          'As soon as you sign-off on the team, they’ll be ready to integrate into your team—just like in-house employees.',
      },
    ],
    contactHeding: (
      <>
        Get in touch to discuss your project, request a quote or even just to
        pick our brains.
      </>
    ),
    contactHeadingIcon: images.contactHeadingIcon,
  },
}
