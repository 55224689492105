import styled from 'styled-components'
import { xl, lg, md, sm, themeColor, white, bodyFont } from 'config/variables'

export const HubSpotFooterFormStyle = styled.div`
  background: transparent;
  position: relative;
  form {
    display: flex;
    flex-wrap: wrap;
  }
  .hs-form-field {
    margin-bottom: 15px;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    .input {
      position: relative;
      width: 100%;
      .hs-input {
        border: 0;
        background: transparent;
        border-bottom: 2px solid rgba(98, 108, 121, 0.5);
        border-radius: 1px;
        color: #232323;
        height: 44px;
        padding: 10px 0;
        font-size: 18px;
        line-height: 22px;
        width: 100%;
        max-width: 100%;
        margin-bottom: 0px;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        -webkit-letter-spacing: -0.17px;
        -moz-letter-spacing: -0.17px;
        -ms-letter-spacing: -0.17px;
        letter-spacing: -0.17px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        font-family: ${bodyFont};
        &::placeholder {
          color: #626c79;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: #626c79;
          opacity: 1;
        }
        &::-ms-input-placeholder {
          color: #626c79;
          opacity: 1;
        }
        &:focus,
        &.fill {
          border-color: ${themeColor};
        }
      }
      textarea.hs-input {
        height: 102px;
        display: block;
        resize: none;
      }
    }
    &.hs-message {
      width: calc(100% - 190px);
      max-width: calc(100% - 190px);
      ${sm(`
        width: 100%;
        max-width: 100%;
     `)}
    }
  }
  .hs_submit {
    padding-left: 10px;
    margin-bottom: 15px;
    width: 180px;
    max-width: 180px;
    margin-left: auto;
    margin-top: 50px;
    ${sm(`
        width: 100%;
        max-width: 150px;
        margin-top: 0;
        margin-left: 0;
        padding-left: 0;
    `)}
    .actions {
      flex: 1;
      border-radius: 4px;
      background-color: ${themeColor};
      box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
      transition: all 0.15s ease-in-out;
      border: 1px solid ${themeColor};
      position: relative;
      overflow: hidden;
      z-index: 1;
      text-align: center;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      letter-spacing: 1.6px;
      text-transform: uppercase;
      color: ${white};
      cursor: pointer;
      display: flex;
      align-items: center;
      transition: all 0.15s ease;
      transition: color 0.15s ease-in-out;
      ${sm(`
        font-size: 14px;
        margin-top: 10px;
     `)}
      &:hover {
        .hs-button {
          color: ${themeColor};
        }
      }
      &:after,
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: ${white};
        z-index: -1;
      }
      &:before {
        transform: translateY(-100%);
        transition: all 15ms ease-in-out;
      }
      &:after {
        transform: translateY(100%);
        transition: all 0.2s ease-in-out;
      }
      &:hover:before {
        transform: translateY(0);
        transition: all 0.2s ease-in-out;
      }
      &:hover:after {
        transform: translateY(0);
        transition: all 0ms ease-in-out;
        transition-delay: 0.2s;
      }
      .hs-button {
        padding: 15px 15px 13px;
        width: 100%;
        border: 0;
        background: transparent;
        cursor: pointer;
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        color: ${white};
        transition: all 0.15s ease-in-out;
        white-space: nowrap;
        ${sm(`
            padding: 10px 15px 12px;
            font-size: 14px;
        `)}
      }
    }
  }
  .hs-error-msgs {
    list-style: none;
    .hs-error-msg,
    .hs-main-font-element {
      margin: 0;
      color: ${themeColor};
      font-size: 12px;
      line-height: 16px;
    }
  }
  .hs_error_rollup {
    width: 100%;
    max-width: 100%;
    order: 6;
  }
`
