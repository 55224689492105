import React, { Component } from 'react'
import { ContactMainSecStyle } from './ContactMainSec.styles'
import ContactStepForm from 'components/ContactStepForm/ContactStepForm'
import { Link } from 'gatsby'

class ContactMainSec extends Component {
  render() {
    const { contactMainSecProps } = this.props.contactMainSecData
    return (
      <ContactMainSecStyle>
        <div className="container">
          <div className="content-part">
            <h3>{contactMainSecProps.contentHeading}</h3>
            <p>{contactMainSecProps.contentPara}</p>
            <div className="contet-list">
              {contactMainSecProps.contentList.map((listProp, i) => {
                return (
                  <div key={i} className="list-blk">
                    <h4>{listProp.title}</h4>
                    <p>{listProp.description}</p>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="contact-form-part">
            <div className="heading">
              <div className="icon">
                <img src={contactMainSecProps.contactHeadingIcon} alt="" />
              </div>
              <h3>{contactMainSecProps.contactHeding}</h3>
            </div>
            <p>
              To apply for a job, visit the{' '}
              <strong>
                <Link to="/careers" className="color-through">
                  career page.
                </Link>
              </strong>
            </p>
            <ContactStepForm location={this.props.location} />
          </div>
        </div>
      </ContactMainSecStyle>
    )
  }
}

export default ContactMainSec
