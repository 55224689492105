import '../fonts/font.css'

/* variables */
export const white = '#fff'
export const black = '#000'
export const primaryColor = '#3d3d3d'
export const themeColor = '#EF5366'
export const themeGrn = '#29ded5'
export const bodyFont = 'Graphik'
export const headingFont = 'CircularStd'
export const metaFont = 'merriweatherbold_italic'
export const metaRegFont = 'merriweatheritalic'
/* variables */

/* media queries */
export const sm = content => `
@media (max-width: 479px) {
  ${content};
}
`
export const md = content => `
@media (max-width: 767px) {
  ${content};
}
`
export const lg = content => `
@media (max-width: 991px) {
  ${content};
}
`
export const xl = content => `
@media (min-width: 992px) and (max-width: 1199px) {
  ${content};
}
`
export const xll = content => `
@media (min-width: 1200px) and (max-width: 1370px) {
  ${content};
}
`
export const device = content => `
@media (max-width: 1024px) {
  ${content};
}
`
export const sh = content => `
@media (min-width: 1200px) and (max-width: 1441px) and (max-height: 850px) {
  ${content};
}
`
/* media queries */
