import styled from 'styled-components'
import {
  lg,
  headingFont,
  sh,
  md,
  xl,
  themeColor,
  primaryColor,
  white,
} from 'config/variables'
import { images } from 'config/images'

export const HeaderStyle = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: ${white};
  transition: all 0.5s ease-in-out;
  box-shadow: 0px -1px 38px rgba(0, 0, 0, 0.07);
  padding: 12px 0;
  ${lg(`
    padding: 15px 0;
  `)}
  .header-top {
    position:relative;
    .header-desktop-menu {
      flex: 1 1 auto;
      ${lg(`
        display: none;
      `)}
      .menu-nav {
        > li {
          padding: 5px 0; 
          > a {
            color: rgba(61, 61, 61, 0.65);
          }
        }
      }
    }
  } 
  .header-container {
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    ${sh(`
      max-width: 1188px;
		`)}
    .button {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.4px;
      text-transform: capitalize;
      font-family: ${headingFont};
      padding: 10px 16px 10px 17px;
      background: ${themeColor};
      color: ${white};
      &:before,
      &:after {
        background: ${white};
      }
      &:hover {
        color: ${themeColor};
      }
      ${lg(`
        display: none;
      `)}
    }
  }
  .header-desktop-menu {
    .menu-nav {
      display: flex;
      justify-content: flex-start;
      padding: 0;
      margin: 0;
      ${lg(`
        flex-direction: column;
      `)}
      > li { 
        margin-left: 40px; 
        ${xl(`
          margin-left: 20px; 
        `)}
        > a {
          transition: all 0.3s ease-in-out;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          font-family: ${headingFont};
          color: ${primaryColor};
          letter-spacing: 0.5px;
          display: block;
          white-space: nowrap;
        }
        &:hover {
          > a {
            color: ${themeColor};
          }
          > .expand-icon {
            > svg {
              color: ${themeColor};
              transform: scaleY(-1);
            }
          }
        }
      }
      li {
        list-style: none;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;
        .expand-icon {
          margin-left: 5px;
          width: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(0, 0, 0, 0.5);
          transform-origin: center;
          svg {
            transition: all 0.3s ease-in-out;
            width: 100%;
            height: auto;
            display: block;
          }
        }
        &.has-submenu {
          border-bottom: 2px solid transparent;
          &:hover {
            border-bottom-color: rgb(239 83 102 / 82%);
            > .mega-menu-wrapper {
              transform: translate(0, 0px);
              opacity: 1;
              visibility: visible;
              pointer-events: auto;
            }
            .mega-menu-list  {
              .mega-menu-list-item {
              &.firstActiveMenu {
                .mega-menu-list-link {
                  background-color: #fff2f3;
                  .expand-icon {
                    opacity: 1;
                  }
                }
                .sub-menu-wrapper {
                  transform: translate(0, 0px);
                  opacity: 1;
                  visibility: visible;
                  pointer-events: auto;
                }
              }
            }
          }
        }
        .mega-menu-wrapper {
          position: absolute;
          left: 0;
          top: 100%;
          width: 100%;
          background: ${white};
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04);
          padding: 24px 0;
          background: ${white};
          transition: all 0.3s ease;
          opacity: 0;
          transform: translate(0, 10px);
          visibility: hidden;
          pointer-events: none;
          z-index: 99;  
          .mega-menu-inner {
            position: relative;
            width: 100%;
            min-height: 322px;
            display: flex;
            flex-direction: column;
          }
          .nav-title {
            font-size: 13px;
            line-height: 16px;
            font-weight: 700;
            color: ${primaryColor};
            margin: 0 0 3px;
            font-family: ${headingFont};
            display:block; 
          }
          .nav-info {
            font-size: 13px;
            line-height: 16px;
            font-weight: 400;
            color: ${primaryColor};
            margin: 0;
            display:block;
          }
          .mega-menu-left-scroll {
            width: 311px;
            overflow: hidden;
            border-right: 1px solid #e3e2ea;
            flex: 1 1 auto;
            height: 100%;
          }
          .mega-menu-list {
            width: calc(311px + 18px); 
            flex: 1 1 auto;
            height: 100%;
            max-height: calc(100vh - 180px);
            overflow: auto;
            .mega-menu-list-item {
              padding-right: 34px;
              &:hover, &.openMenu {
                .mega-menu-list-link {
                  background-color: #fff2f3;
                  .expand-icon {
                    opacity: 1;
                  }
                }
                .sub-menu-wrapper {
                  transform: translate(0, 0px);
                  opacity: 1;
                  visibility: visible;
                  pointer-events: auto;
                }
              } 
              .mega-menu-list-link {
                padding: 15px 20px 15px 16px;
                border-radius: 4px;
                display: block;
                width: 100%;
                transition: all 0.3s ease-in-out;
                position: relative;
                .expand-icon {
                  position: absolute;
                  top: 50%;
                  right: 8px;
                  opacity: 0;
                  transition: all 0.3s ease-in-out;
                  margin: -5px 0 0;
                  transform: rotate(-90deg);
                }
              }
            }
          }
        }
        .sub-menu-wrapper {
          position: absolute;
          top: 0;
          right: 0;
          width: calc(100% - 311px);
          max-height: 100%;
          overflow: auto;
          height: 100%;
          transition: all 0.3s ease;
          opacity: 0;
          transform: translate(0, 10px);
          visibility: hidden;
          pointer-events: none;
          padding: 6px 0;
          background: ${white};
          .sub-menu-list {
            display: flex;
            flex-wrap: wrap;
            .sub-menu-list-item {
              flex: 0 0 33.33%;
              width: 33.33%;
              max-width: 33.33%;
              padding: 0 17px;
              display: block;
              .sub-menu-list-link {
                display: flex; 
                padding: 20px;
                border-radius: 4px;
                transition: all 0.3s ease;
                ${xl(`
                   padding: 10px;
                `)}
                &:hover {
                  background-color: #fff2f3;
                }
                .nav-icon {
                  flex: 0 0 50px;
                  width: 50px;
                  max-width: 50px;
                }
                .nav-text {
                  align-self: center;
                  padding: 0 0 0 10px;
                  flex: 1 1 auto;
                }
              }
            }
          }
          .sub-menu-details-content {
            padding: 0 37px;
            display: flex;
            .sub-menu-details-link {
              display: flex;
              color: ${primaryColor};
              .nav-icon {
                flex: 0 0 auto;
              }
              .left-details {
                align-self: center;
                padding-left: 15px;
                max-width: 500px;
                .menu-title-lg {
                  margin-bottom: 0;
                  display:block; 
                  font-family: ${headingFont};
                  font-weight: 700;
                }
                .menu-info-lg {
                  margin: 5px 0 0;
                  display:block;
                  
                }
              }
            }
          }
        }
      }
    }
  }
`
