import styled from 'styled-components'
import { images } from 'config/images'
import { primaryColor, black, white, metaFont } from 'config/variables'

export const CookiesPopupStyle = styled.div`
  width: 280px;
  background: url(${images.cookieBg}) no-repeat center;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 14px 64px 20px 20px;
  background-size: 100% 100%;
  z-index: 98;
  left: 16px;
  bottom: 16px;
  .text-caption {
    p {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 15px;
      color: ${primaryColor};
      letter-spacing: 0.1px;
      span {
        font-family: ${metaFont};
        font-weight: 700;
        a {
          color: ${primaryColor};
        }
      }
    }
    .button {
      font-size: 14px;
      line-height: 15px;
      padding: 9px 22px 10px;
      border: 1px solid ${black};
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1.5px;
      border-radius: 4px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      cursor: pointer;
      background: transparent;
      &:hover {
        color: ${white};
      }
      transition: color 0.15s ease-in-out;
      display: inline-block;
      &:after,
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: ${black};
        z-index: -1;
      }
      &:before {
        transform: translateY(-100%);
        transition: transform 15ms ease-in-out;
      }
      &:after {
        transform: translateY(100%);
        transition: transform 0.2s ease-in-out;
      }
      &:hover:before {
        transform: translateY(0);
        transition: transform 0.2s ease-in-out;
      }
      &:hover:after {
        transform: translateY(0);
        transition: transform 0ms ease-in-out;
        transition-delay: 0.2s;
      }
    }
  }
`
