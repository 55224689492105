import styled from 'styled-components'
import {
  lg,
  headingFont,
  themeColor,
  bodyFont,
  primaryColor,
  sh,
  white,
  xl,
} from 'config/variables'

export const NavStyle = styled.nav`
  ${lg(`
    display: none;
    `)}
  > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 0 20px;
    > li {
      list-style: none;
      font-family: ${headingFont};
      line-height: 18px;
      font-weight: 500;
      letter-spacing: 0.007em;
      font-size: 16px;
      display: flex;
      align-items: center;
      ${xl(`
        font-size: 15px;
      `)}
      > a {
        transition: all 0.3s cubic-bezier(0.29, 0.19, 0.405, 1);
        color: rgba(61, 61, 61, 0.65);
        padding: 10px 5px;
        display: flex;
        align-items: center;
        position: relative;
        gap: 5px;
        ${sh(`
          padding: 8px 0 8px;
        `)}
        &::after {
          content: '';
          position: absolute;
          left: 0;
          width: 0px;
          bottom: 0px;
          right: 0px;
          height: 1px;
          background-color: ${themeColor};
          margin: 0 auto;
          transition: all 0.3s cubic-bezier(0.29, 0.19, 0.405, 1);
        }
        .menu-icon {
          display: block;
          transition: all 0.3s cubic-bezier(0.29, 0.19, 0.405, 1);
          svg {
            display: block;
          }
        }
        &.active {
          color: ${themeColor};
        }
      }
      &:hover {
        > a {
          color: ${themeColor};
          &::after {
            width: 100%;
          }
          .menu-icon {
            transform: scaleY(-1);
          }
        }
        .submenu {
          transform: translate(-50%, 0px);
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
        }
      }
      .submenu {
        position: absolute;
        width: 100%;
        padding: 20px 0;
        left: 50%;
        transform: translate(-50%, 10px);
        top: 45px;
        background: ${white};
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        border-radius: 5px;
        transition: all 0.3s ease;
        > .header-container {
          > ul {
            display: flex;
            flex-direction: column;
            z-index: 1;
            position: relative;
            background: ${white};
            width: 100%;
            min-height: 358px;
            > li {
              list-style: none;
              width: 100%;
              display: flex;
              width: 100%;
              > a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 10px;
                transition: all 0.3s cubic-bezier(0.29, 0.19, 0.405, 1);
                border-radius: 10px;
                width: 309px;
                flex: 0 0 309px;
                min-height: 71px;
                color: ${primaryColor};
                .menu-arrow-icon {
                  opacity: 0;
                  transition: all 0.3s cubic-bezier(0.29, 0.19, 0.405, 1);
                }
              }
              &:hover,
              &.active {
                > a {
                  color: ${themeColor};
                  background-color: #f2f2f2;
                  &[href='/#'] {
                    color: ${primaryColor};
                  }
                  .menu-arrow-icon {
                    opacity: 1;
                  }
                }
                .submenu-list {
                  opacity: 1;
                  pointer-events: auto;
                  z-index: 2;
                }
              }
            }
          }
          .submenu-list {
            position: absolute;
            right: 0px;
            top: 0px;
            width: calc(100% - 323px);
            background-color: ${white};
            opacity: 0;
            height: 100%;
            pointer-events: none;
            padding-left: 14px;
            margin-left: 14px;
            border-left: 1px solid #dae3f6;
            z-index: 1;
            ul {
              display: grid;
              gap: 20px;
              list-style: none;
              grid-template-columns: 1fr 1fr 1fr;
              li {
                a {
                  display: flex;
                  gap: 10px;
                  padding: 14px 23px;
                  align-items: center;
                  border-radius: 10px;
                  color: ${primaryColor};
                  transition: all 0.3s cubic-bezier(0.29, 0.19, 0.405, 1);
                  .icon {
                    flex: 0 0 50px;
                    width: 50px;
                    height: auto;
                  }
                  &:hover {
                    background-color: #f2f2f2;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
