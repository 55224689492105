import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const navProps = [
  {
    navLink: '#',
    navTitle: 'Services',
    type: EXTERNAL_LINK,
    navSubmenu: [
      {
        navLink: '/services/digital-product-engineering/',
        navTitle: 'Products and Platforms Engineering',
        activeClass: 'active',
        navSubmenu: [
          {
            navLink: '/services/project-strategy/',
            type: EXTERNAL_LINK,
            navTitle: 'Product Strategy',
            menuIcon: images.productStrategyMicon,
          },
          {
            navLink: '/services/performance-engineering/',
            type: EXTERNAL_LINK,
            navTitle: 'Performance Engineering and Testing',
            menuIcon: images.performanceEngTestingMicon,
          },
          {
            navLink: '/services/software-product-development/',
            navTitle: 'Product Engineering and Development',
            menuIcon: images.productEngDevMicon,
          },
          {
            navLink: '/services/application-modernization/',
            type: EXTERNAL_LINK,
            navTitle: 'Application Modernization',
            menuIcon: images.appModernizationMicon,
          },
          {
            navLink: '/services/software-testing/',
            navTitle: 'QA Engineering',
            menuIcon: images.qaEngineeringMicon,
          },
        ],
      },
      {
        navLink: '/services/cloud-native-engineering/',
        navTitle: 'Cloud and DevOps Engineering',
        navSubmenu: [
          {
            navLink: '/services/cloud-consulting/',
            type: EXTERNAL_LINK,
            navTitle: 'Cloud Advisory Services',
            menuIcon: images.cloudAdvisoryServicesMicon,
          },
          {
            navLink: '/services/cloud-architecture-design/',
            type: EXTERNAL_LINK,
            navTitle: 'Architecture Reviews',
            menuIcon: images.architectureReviewsMicon,
          },
          {
            navLink: '/services/cloud-migration/',
            type: EXTERNAL_LINK,
            navTitle: 'Migration and Modernization',
            menuIcon: images.migrationModernizationMicon,
          },
          {
            navLink: '/services/devops/',
            navTitle: 'DevOps Engineering',
            menuIcon: images.devopsEngineeringMicon,
          },
        ],
      },
      {
        navLink: '/services/data-engineering/',
        type: EXTERNAL_LINK,
        navTitle: 'Data Engineering',
        navSubmenu: [
          {
            navLink: '/services/data-engineering/',
            type: EXTERNAL_LINK,
            navTitle: 'Data Engineering',
            menuIcon: images.dataEngineeringMicon,
          },
          {
            navLink: '/services/data-analytics-consulting/',
            type: EXTERNAL_LINK,
            navTitle: 'Big Data Analytics',
            menuIcon: images.bigDataAnalyticsMicon,
          },
        ],
      },
      {
        navLink: '/services/gen-ai-ml-development/',
        type: EXTERNAL_LINK,
        navTitle: 'AI/ML Engineering',
        navSubmenu: [
          {
            navLink: '/services/generative-ai-development/',
            type: EXTERNAL_LINK,
            navTitle: 'GenAI',
            menuIcon: images.genAIMicon,
          },
          {
            navLink: '/services/data-science-consulting/',
            type: EXTERNAL_LINK,
            navTitle: 'Data Science',
            menuIcon: images.dataScienceMicon,
          },
          {
            navLink: '/services/machine-learning-development/',
            type: EXTERNAL_LINK,
            navTitle: 'Machine Learning',
            menuIcon: images.machineLearningMicon,
          },
          {
            navLink: '/services/mlops/',
            type: EXTERNAL_LINK,
            navTitle: 'MLOps',
            menuIcon: images.mlOpsMicon,
          },
        ],
      },
      {
        navLink: '/services/digital-experience-design/',
        type: EXTERNAL_LINK,
        navTitle: 'Digital and Experience Engineering',
        navSubmenu: [
          {
            navLink: '/services/custom-software-development/',
            navTitle: 'Custom Software Development',
            menuIcon: images.customSoftwareDevMicon,
          },
          {
            navLink: '/services/application-development/',
            navTitle: 'Application Development',
            menuIcon: images.applicationDevMicon,
          },
          {
            navLink: '/services/application-modernization/',
            type: EXTERNAL_LINK,
            navTitle: 'Application Modernization',
            menuIcon: images.appModernizationMicon,
          },
        ],
      },
    ],
  },
  {
    navLink: '#',
    navTitle: 'Industries',
    navSubmenu: [
      {
        navLink: '#',
        navTitle: 'Industries',
        activeClass: 'active',
        navSubmenu: [
          {
            navLink: '/industry/finance/',
            navTitle: 'Financial Services',
            menuIcon: images.bfsiMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/industry/healthcare/',
            navTitle: 'Healthcare and Life Sciences',
            menuIcon: images.healthcareMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/industry/retail/',
            navTitle: 'Retail and E-commerce',
            menuIcon: images.retailMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/industry/supply-chain/',
            navTitle: 'Supply Chain & Logistics',
            menuIcon: images.supplychainMicon,
            type: EXTERNAL_LINK,
          },
        ],
      },
    ],
  },
  {
    navLink: '/case-studies/',
    navTitle: 'Case Studies',
    type: EXTERNAL_LINK,
  },
  {
    navLink: '#',
    navTitle: 'Resources',
    navSubmenu: [
      {
        navLink: '#',
        navTitle: 'Resources',
        activeClass: 'active',
        navSubmenu: [
          {
            navLink: '/category/ai-ml-development/',
            navTitle: 'AI & ML Insights',
            menuIcon: images.aimlInsightsMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/category/product-engineering/',
            navTitle: 'Product Engineering Insights',
            menuIcon: images.productEngInsightsMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/category/cloud/',
            navTitle: 'Cloud Engineering Insights',
            menuIcon: images.cloudEngInsightsMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/blog/',
            navTitle: 'Blog',
            menuIcon: images.blogNMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/ebooks/',
            navTitle: 'Ebooks',
            menuIcon: images.ebooksNMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/blog/#news&insights',
            navTitle: 'News and Tech Insights',
            menuIcon: images.newsNMicon,
            type: EXTERNAL_LINK,
          },
        ],
      },
    ],
  },
  {
    navLink: '/about-us/',
    navTitle: 'About Us',
  },
]
