import styled from 'styled-components'
import { white, themeColor, md, lg, xl } from 'config/variables'

export const EventBannerStyle = styled.div`
  .button {
    background: ${themeColor};
    color: ${white};
    &:before,
    &:after {
      background: ${white};
    }
    &:hover {
      color: ${themeColor};
    }
  }
  background: linear-gradient(
    90deg,
    rgba(5, 18, 45, 1) 0%,
    rgba(5, 23, 45, 1) 25%,
    rgba(114, 1, 93, 1) 53%,
    rgba(8, 67, 164, 1) 71%,
    rgba(24, 171, 140, 1) 86%
  );
  padding: 20px 0;
  .webinar-banner-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    ${xl(`
      gap: 20px;
    `)}
    ${lg(`
      flex-direction: column;
      align-items: flex-start;
    `)}
    .webinar-banner-left {
      display: flex;
      gap: 15px;
      ${xl(`
         gap: 10px;
      `)}
      .devider {
        background-color: #fff;
        width: 1px;
        flex: 0 0 1px;
      }
      .logo-img {
        flex: 0 0 auto;
        align-self: center;
        ${md(`
          max-width: 60px;
        `)}
        &.simform-logo {
          max-width: 184px;
          ${md(`
            max-width: 160px;
          `)}
        }
      }
    }
    .webinar-banner-right {
      max-width: 861px;
      .webinar-info {
        display: flex;
        align-items: center;
        gap: 20px;
        ${xl(`
          gap: 10px;
        `)}
        ${lg(`
          max-width: 100%;
          width: 100%;
        `)}
        ${md(`
          flex-direction: column;
          align-items: flex-start;
        `)}
        .webinar-info-item {
          display: flex;
          align-items: center;
          gap: 10px;
          color: #fff;
          font-family: 'CircularStd';
          font-size: 24px;
          font-style: normal;
          font-weight: 450;
          line-height: normal;
          ${xl(`
            gap: 5px;
            font-size: 18px;
          `)}
          ${lg(`
            gap: 5px;
            font-size: 18px;
          `)}
        }
        .webinar-info-button {
          margin-left: auto;
          ${md(`
            margin: 0;
          `)}
        }
      }
    }
  }
  h2 {
    color: #fff;
    font-size: 32px;
    line-height: 41px;
    margin-bottom: 8px;
    font-family: 'CircularStd';
    ${xl(`
      font-size: 24px;
      line-height: 32px;
    `)}
    ${lg(`
      font-size: 24px;
      line-height: 32px;
    `)}
  }
`
