import React from 'react'

function MenuIcon() {
  return (
    <span className="menu-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 17 17"
      >
        <g clipPath="url(#clip0_686_219)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M4.137 5.167l4 4 4-4L13.47 6.5l-5.333 5.333L2.803 6.5l1.334-1.333z"
            clipRule="evenodd"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_686_219">
            <path
              fill="#fff"
              d="M0 0H16V16H0z"
              transform="translate(.135 .5)"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </span>
  )
}

export default MenuIcon
