import React from 'react'
import SEO from 'components/Seo'
import { ContactProps } from '../components/Props/contact'
import styled from 'styled-components'
import Layout from 'components/Layout/Layout'
import ContactAddress from 'components/ContactAddress/ContactAddress'
import ContactMainSec from 'components/ContactMainSec/ContactMainSec'

export const ContactPage = styled.div`
  .content-area {
    &:after {
      height: 480px;
    }
  }
`

const DDT = props => {
  const { location } = props
  return (
    <Layout showFooter={false} mainClass="contact-page" location={location}>
      <SEO
        title="Contact Us"
        description="Do you have an idea? Get in touch with us to know more about how we can help you! We serve the US & Canada across ten different cities. Don't be shy, say hi!"
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/contact/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/contact/',
          },
          {
            property: 'og:image',
            content: 'https://www.simform.com/banner.png',
          },
        ]}
      />
      <ContactPage>
        <ContactMainSec contactMainSecData={ContactProps} location={location} />
        <ContactAddress contactData={ContactProps} />
      </ContactPage>
    </Layout>
  )
}

export default DDT
