import React, { memo, useState, useEffect } from 'react'
import { CookiesPopupStyle } from './CookiesPopup.styles'
import { Link } from 'gatsby'
import { fetchItem, COOKIES_STATUS, storeItem } from 'config/utils'

const CookiesPopup = memo(() => {
  let [hidden, setHide] = useState(true)

  useEffect(() => {
    let status = fetchItem(COOKIES_STATUS)
    let hideCookies
    if (!status) {
      hideCookies = false
    } else {
      status === 'true' ? (hideCookies = true) : (hideCookies = false)
    }
    setHide(hideCookies)
  }, [])

  const hideEvent = () => {
    setHide(true)
    storeItem(COOKIES_STATUS, 'true')
  }

  return (
    !hidden && (
      <CookiesPopupStyle>
        <div className="text-caption">
          <p>
            We use cookies to improve your browsing experience. And&nbsp;
            <span>Pizzas</span> to boost morale on Fridays.&nbsp;
            <span>
              Learn about our{' '}
              <Link to="/privacy-policy/">
                <u>Privacy policy</u>
              </Link>{' '}
              here.
            </span>
          </p>
          <button className="button" onClick={hideEvent}>
            Okay
          </button>
        </div>
      </CookiesPopupStyle>
    )
  )
})

export default CookiesPopup
