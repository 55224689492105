import React from 'react'
import { ContactAddressStyle } from './ContactAddress.styles'
import loadable from '@loadable/component'
import 'slick-carousel/slick/slick.css'
import { usePreventScroll } from 'components/hooks/usePreventScroll'

const ReactSlick = loadable.lib(() => import('react-slick'))

const settings = {
  dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  centerPadding: 0,
  touchMove: false,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 3,
        touchMove: true,
      },
    },
    {
      breakpoint: 766,
      settings: {
        slidesToShow: 2,
        touchMove: true,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        touchMove: true,
      },
    },
  ],
}

function SamplePrevArrow(props) {
  const { onClick } = props
  return (
    <button className="slick-arrow slick-prev" onClick={onClick}>
      <svg width="32" height="25" viewBox="0 0 32 25">
        <path
          fill="#3D3D3D"
          fillRule="evenodd"
          d="M0 12a1.22 1.22 0 0 1 0-.13v-.11a1.25 1.25 0 0 1 0-.13l.05-.1.07-.12.07-.09.08-.1L11 .39a1.3 1.3 0 0 1 1.82 0 1.25 1.25 0 0 1 0 1.79l-8.43 8.56h26.32a1.3 1.3 0 0 1 0 2.59H4.29l8.55 8.55a1.25 1.25 0 0 1 0 1.79 1.3 1.3 0 0 1-1.82 0L.37 13l-.08-.09-.08-.09-.06-.11-.06-.11a1.18 1.18 0 0 1 0-.12 1.22 1.22 0 0 1 0-.12 1.16 1.16 0 0 1 0-.12 1.2 1.2 0 0 1 0-.13L0 12z"
        />
      </svg>
    </button>
  )
}

function SampleNextArrow(props) {
  const { onClick } = props
  return (
    <button className="slick-arrow slick-next" onClick={onClick}>
      <svg width="32" height="25" viewBox="0 0 32 25">
        <path
          fill="#3D3D3D"
          fillRule="evenodd"
          d="M32 12a1.22 1.22 0 0 0 0-.13v-.11a1.25 1.25 0 0 0 0-.13l-.05-.1-.07-.12-.07-.09-.08-.1L21 .39a1.3 1.3 0 0 0-1.82 0 1.25 1.25 0 0 0 0 1.79l8.43 8.56H1.29a1.3 1.3 0 0 0 0 2.59h26.42l-8.55 8.55a1.25 1.25 0 0 0 0 1.79 1.3 1.3 0 0 0 1.82 0L31.63 13l.08-.09.08-.09.06-.11.06-.11a1.18 1.18 0 0 0 0-.12 1.22 1.22 0 0 0 0-.12 1.16 1.16 0 0 0 0-.12 1.2 1.2 0 0 0 0-.13L32 12z"
        />
      </svg>
    </button>
  )
}

function ContactAddress(props) {
  const { contcatAddressProp } = props.contactData
  usePreventScroll('.address-listing')
  return (
    <ContactAddressStyle>
      <div className="container">
        {/* <h1>{contcatAddressProp.heading}</h1> */}
        <div className="address-content-wrapper">
          <div className="address-listing">
            <ReactSlick>
              {({ default: Slider }) => (
                <Slider {...settings}>
                  {contcatAddressProp.addressList.map((caddressProp, i) => {
                    return (
                      <div key={i} className="address-blk">
                        {caddressProp.URL ? (
                          <h5>
                            <a href={caddressProp.URL}>
                              {caddressProp.countryName}
                            </a>
                          </h5>
                        ) : (
                          <h5>{caddressProp.countryName}</h5>
                        )}
                        <p>{caddressProp.address}</p>
                        <a className="call" href={caddressProp.telTag}>
                          {caddressProp.tel}
                        </a>
                      </div>
                    )
                  })}
                </Slider>
              )}
            </ReactSlick>
          </div>
        </div>
      </div>
    </ContactAddressStyle>
  )
}

export default ContactAddress
