import 'intersection-observer'
import React, { useState, useEffect } from 'react'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import { GlobalStyles } from './GlobalStyles'
import CookiesPopup from 'components/CookiesPopup/CookiesPopup'
import {
  appendDMCAScript,
  appendChatScript,
  appendAnalyticScripts,
} from 'config/scriptAdders'
import {
  isWindowDefined,
  storeItem,
  GOOGLE_PARAMS,
  getViewerCountry,
} from 'config/utils'
import {
  appendScript,
  appendContactPageScript,
} from 'config/dynamicPagesScript'
import { useHideChat } from 'components/hooks/useHideChat'
import EventBanner from 'components/EventBanner/EventBanner'

const Layout = ({
  children,
  mainClass,
  location,
  showFooter = true,
  data = {},
}) => {
  const { search, pathname } = location
  let [footer, setFooter] = useState(false)

  useHideChat({ pathname })

  useEffect(() => {
    // appendScript(pathname)

    if (pathname === '/contact/') {
      appendContactPageScript()
    }
  }, [pathname])

  useEffect(() => {
    if (isWindowDefined) {
      window.addEventListener('mousemove', appendAnalyticScripts, {
        once: true,
      })
    }
  }, [])

  useEffect(() => {
    if (search) {
      storeItem(GOOGLE_PARAMS, search)
    }
  }, [search])

  useEffect(() => {
    setTimeout(() => {
      setFooter(true)
    }, 2000)
  }, [])

  useEffect(() => {
    if (footer) {
      window.addEventListener('mousemove', appendDMCAScript, { once: true })
    }
  }, [footer])

  useEffect(() => {
    getViewerCountry().then(country => {
      if (country) {
        if (country === 'US' || country === 'UK' || country === 'CA') {
          window.addEventListener('mousemove', appendChatScript, { once: true })
        }
      } else {
        window.addEventListener('mousemove', appendChatScript, { once: true })
      }
    })
  }, [])

  return (
    <>
      <GlobalStyles />
      <div className="page-main">
        <div className={`content-area ${mainClass || ''}`}>
          <Header />
          {children}
          {showFooter && footer && <Footer {...data} />}
          <CookiesPopup />
        </div>
      </div>
    </>
  )
}

export default Layout
