import styled from 'styled-components'
import {
  white,
  sm,
  sh,
  headingFont,
  primaryColor,
  bodyFont,
  themeColor,
  xl,
  lg,
  md,
  themeGrn,
} from 'config/variables'
import { images } from 'config/images'

export const ContactStepFormStyle = styled.div`
  form {
    .steps {
      margin-bottom: 34px;
      .title {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.2px;
        margin-bottom: 30px;
        font-family: ${headingFont};
        font-weight: 700;
        ${md(`
          line-height: 24px;
        `)}
      }
      .input-field-row {
        display: flex;
        margin-bottom: 22px;
        justify-content: space-between;
        ${sm(`
          margin-bottom: 15px;
          flex-wrap: wrap;
        `)}
        .form-field-blk {
          position: relative;
          width: 100%;
          &.column {
            width: calc(50% - 10px);
            ${sm(`
               width: 100%;
               margin-bottom: 15px;
            `)}
            &:last-child {
              ${sm(`
                margin-bottom: 0px;
              `)}
            }
          }
          .label-input-field {
            width: 100%;
            height: 55px;
            max-width: 100%;
            margin-bottom: 0px;
            padding: 16px 20px 17px 22px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            background-color: transparent;
            background-image: linear-gradient(180deg, transparent, transparent);
            transition: all 300ms ease;
            color: #3d3d3d;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.17px;
            appearance: none;
            outline: none;
            ${sh(`
            height: 50px;
            padding: 14px 18px 15px 20px;
            `)}
            ${sm(`
            padding: 11px 15px 9px 16px;
            height: 44px;
            font-size: 14px;
            `)}
            &.form-error {
              border-color: ${themeColor};
            }
            & + label {
              pointer-events: none;
            }
            & + label + .error-msg {
              color: ${themeColor};
              padding-top: 5px;
              display: block;
            }
            &:focus {
              border-color: ${themeGrn};
            }
            &:focus + label {
              top: -6px;
              font-size: 11px;
              line-height: 12px;
              padding: 0 2px;
              background: #f3f5ff;
              z-index: 9;
              text-transform: uppercase;
            }
            &.fill + label {
              top: -6px;
              font-size: 11px;
              line-height: 12px;
              padding: 0 2px;
              background: #f3f5ff;
              z-index: 9;
              text-transform: uppercase;
            }
            &.textarea {
              height: 132px;
              resize: none;
              display: block;
            }
          }
          label {
            position: absolute;
            left: 22px;
            top: 15px;
            margin-bottom: 0px;
            transition: all 300ms ease;
            color: #504f4f;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            letter-spacing: -0.17px;
            text-transform: capitalize;
            cursor: text;
            max-width: 90%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            ${sh(`
            left: 18px;
            top: 13px;
            font-size: 15px;
            line-height: 21px;
          `)} ${sm(`
            left: 16px;
            top: 10px;
            font-size: 14px;
          `)};
          }
        }
        .range-input-field {
          padding: 15px 22px 20px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          width: 100%;
          .title-row {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
            .row-title {
              font-size: 16px;
              line-height: 22px;
              letter-spacing: -0.17px;
              color: #504f4f;
              ${md(`
                font-size: 15px;
                line-height: 21px;
              `)}
              ${sm(`
                font-size: 14px;
                line-height: 20px;
                padding-right: 10px;
              `)}
            }
            .budget {
              font-size: 16px;
              line-height: 22px;
              letter-spacing: -0.17px;
              color: #504f4f;
              span {
                color: ${primaryColor};
              }
              ${md(`
                font-size: 15px;
                line-height: 21px;
              `)}
              ${sm(`
                font-size: 14px;
                line-height: 20px;
              `)}
            }
          }
          .input-range {
            position: relative;
            width: 85%;
            margin: 65px auto 40px;
            .input-range__track {
              width: 100%;
              height: 5px;
              border-radius: 5px;
              background: rgba(145, 225, 255, 0.2);
              position: relative;
              transition: all 0.1s ease-out;
              .input-range__track--active {
                background: rgba(145, 225, 255, 0.4);
              }
              .input-range__slider-container {
                top: -12px;
                transition: all 0.1s ease-out;
                transform: translateX(-50%);
                .input-range__label {
                  background: #fff;
                  padding: 6px 7px;
                  border-radius: 3px;
                  box-shadow: 0 3px 4px 0 rgba(109, 216, 255, 0.49);
                  font-size: 11px;
                  letter-spacing: -0.12px;
                  line-height: 15px;
                  position: absolute;
                  top: -42px;
                  left: 50%;
                  transform: translateX(-50%);
                  margin-bottom: 16px;
                  display: block;
                  &:after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    margin-left: -7px;
                    bottom: -8px;
                    left: 50%;
                    box-sizing: border-box;
                    border: 5px solid;
                    border-color: transparent transparent #fff #fff;
                    transform-origin: 0 0;
                    transform: rotate(-45deg);
                    box-shadow: -3px 3px 3px 0 rgba(109, 216, 255, 0.4);
                  }
                }
                .input-range__slider {
                  width: 27px;
                  height: 27px;
                  background: #fff;
                  border: 5px solid #6dd8ff;
                  border-radius: 50%;
                  margin: 0 auto;
                  transition: all 0.1s ease-out;
                  cursor: pointer;
                }
              }
            }
            .input-range__label {
              position: absolute;
              font-size: 14px;
              letter-spacing: -0.15px;
              line-height: 19px;
              top: 15px;
              &.input-range__label--min {
                left: 0;
                transform: translateX(-50%);
              }
              &.input-range__label--max {
                right: 0;
                transform: translateX(50%);
              }
            }
          }
        }
        .input-file-row {
          width: 100%;
          position: relative;
          position: relative;
          .error-msg {
            color: ${themeColor};
            padding-top: 5px;
            display: block;
          }
          label {
            display: flex;
            padding: 18px 20px;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            border: 1px dashed rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            ${sm(`
              flex-wrap: wrap;
              justify-content: center;
            `)}
            &.form-error {
              border-color: ${themeColor};
            }
            .file-content {
              display: flex;
              align-items: center;
              position: relative;
              ${sm(`
                margin-bottom: 15px;
              `)}
              .icon {
                margin-right: 15px;
              }
              .content-label {
                font-size: 16px;
                letter-spacing: -0.17px;
                line-height: 22px;
                color: #504f4f;
                ${md(`
                  font-size: 15px;
                  line-height: 21px;
                `)}
              }
            }
            .row-wrap {
              display: flex;
              align-items: center;
              .btn-close {
                width: 15px;
                height: 15px;
                margin-right: 15px;
                position: relative;
                z-index: 9;
                &:after {
                  content: '';
                  width: 100%;
                  height: 2px;
                  background: #8f999e;
                  position: absolute;
                  top: 7.5px;
                  left: 0;
                  transform: rotate(45deg);
                }
                &:before {
                  content: '';
                  width: 100%;
                  height: 2px;
                  background: #8f999e;
                  position: absolute;
                  top: 7.5px;
                  left: 0;
                  transform: rotate(-45deg);
                }
              }
              .button {
                background: ${themeColor};
                border-radius: 4px;
                box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
                padding: 9px 23px 11px;
                font-size: 16px;
                letter-spacing: 1.6px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 500;
              }
            }
          }
          .file-field {
            height: 100%;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            cursor: pointer;
          }
        }
        .service-listing {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          margin-bottom: -20px;
          .service-col {
            position: relative;
            display: flex;
            width: calc(50% - 10px);
            margin-bottom: 20px;
            ${md(`
              width: 100%;
            `)}
            label {
              background: #fff;
              padding: 15px 16px;
              border-radius: 5px;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
              position: relative;
              padding-left: 54px;
              font-size: 16px;
              line-height: 25px;
              letter-spacing: -0.17px;
              transition: all 0.3s ease;
              width: 100%;
              cursor: pointer;
              ${sm(`
                font-size: 15px;
                line-height: 24px;
              `)}
              br {
                ${sm(`
                  display: none;
                `)}
              }
              &:after {
                content: '';
                width: 23px;
                height: 23px;
                position: absolute;
                top: 20px;
                left: 16px;
                border: 3px solid #f2dbcb;
                background-color: transparent;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                transition: all 0.3s ease;
              }
            }
            input[type='checkbox'] {
              visibility: hidden;
              width: 0;
              height: 0;
              &:checked + label {
                box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.13);
                &:after {
                  background-image: url(${images.iconTickWhite});
                  background-color: #f2dbcb;
                }
              }
            }
            &.periwinkle {
              label {
                &:after {
                  border-color: #c2d2f5;
                }
              }
              input[type='checkbox'] {
                visibility: hidden;
                &:checked + label {
                  &:after {
                    background-color: #c2d2f5;
                  }
                }
              }
            }
            &.robin-s-egg {
              label {
                &:after {
                  border-color: #a4eae4;
                }
              }
              input[type='checkbox'] {
                visibility: hidden;
                &:checked + label {
                  &:after {
                    background-color: #a4eae4;
                  }
                }
              }
            }
            &.beige {
              label {
                &:after {
                  border-color: #f9da9b;
                }
              }
              input[type='checkbox'] {
                visibility: hidden;
                &:checked + label {
                  &:after {
                    background-color: #f9da9b;
                  }
                }
              }
            }
            &.powder-pink {
              label {
                &:after {
                  border-color: #ecc2e5;
                }
              }
              input[type='checkbox'] {
                visibility: hidden;
                &:checked + label {
                  &:after {
                    background-color: #ecc2e5;
                  }
                }
              }
            }
            &.periwinkle {
              label {
                &:after {
                  border-color: #c2d2f5;
                }
              }
              input[type='checkbox'] {
                visibility: hidden;
                &:checked + label {
                  &:after {
                    background-color: #c2d2f5;
                  }
                }
              }
            }
            &.light-peach {
              label {
                &:after {
                  border-color: #f2dbcb;
                }
              }
              input[type='checkbox'] {
                visibility: hidden;
                &:checked + label {
                  &:after {
                    background-color: #f2dbcb;
                  }
                }
              }
            }
          }
        }
      }
      .date-picker-row {
        .date-hidden-field {
          visibility: hidden;
          height: 0px;
          margin: 0;
          border: 0;
          padding: 0px;
          width: 0px;
        }
        .heading-row {
          display: flex;
          justify-content: space-between;
          position: relative;
          ${md(`
            flex-wrap: wrap;
            justify-content: center;
          `)}
          .meeting {
            position: relative;
            font-size: 14px;
            line-height: 19px;
            color: ${primaryColor};
            padding-left: 31px;
            ${md(`
              margin: 0 10px 15px;
            `)}
            &:before {
              content: '';
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background: ${themeColor};
              top: 3px;
              left: 0;
              position: absolute;
            }
          }
          .day {
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            color: ${primaryColor};
            font-family: ${bodyFont};
            ${md(`
              margin: 0 10px 15px;
              position: relative;
              transform: initial;
              left: 0;
            `)}
          }
        }
        .rwdpDayPickerContainer {
          .rwdpDayBoxDesktop {
            .rwdp-DayCircle-today {
              display: none;
            }
            .rwdp-DayCircle-container {
              margin: 24px 7px 20px;
              padding: 8px 0;
              ${xl(`
                margin: 24px 4px 20px;
              `)}
              ${lg(`
                margin: 24px 13px 20px;
              `)}
              &:disabled {
                /* border-left: 1px solid rgba(61, 61, 61, 0.2);*/
                margin: 24px 0 20px;
                .rwdp-DayCircle-unavailable {
                  display: none;
                }
              }
              &.currentDay {
                pointer-events: none;
                .rwdp-DayCircle {
                  border-color: #b1b1b1;
                  color: #000 !important;
                  background: rgba(177, 177, 177, 0.5) !important;
                }
              }
              .rwdp-DayCircle {
                background: rgba(239, 83, 102, 0.06);
                border: 3px solid rgba(239, 83, 102, 0.5);
                color: #000;
                font-size: 14px;
                font-family: ${bodyFont};
                transition: all 0.3s ease;
                strong {
                  font-weight: 500;
                }
                ${xl(`
                  width: 70px;
                  height: 70px;
                  font-size: 13px;
                `)}
                &.rwdp-DayCircle-selected {
                  background: ${themeColor};
                  color: #fff;
                }
              }
            }
          }
          .rwdpDayBoxMobil {
            .rwdp-DayCircle-container {
              &.currentDay {
                pointer-events: none;
                .rwdp-DayCircle {
                  border-color: #b1b1b1;
                  color: #000;
                  background: rgba(177, 177, 177, 0.5);
                }
              }
            }
            .rwdp-DayCircle {
              border-color: rgba(239, 83, 102, 0.5);
              color: #000;
              font-size: 13px;
              &.rwdp-DayCircle-selected {
                background: ${themeColor};
                color: #fff;
              }
            }
          }
          .rwdpNextWeekArrow,
          .rwdpPrevWeekArrow {
            width: 12px;
            height: 12px;
            top: calc(50% - 10px);
            border: 4px solid ${themeColor};
            border-top: 0;
            border-left: 0;
            transform: rotate(135deg);
          }
          .rwdpNextWeekArrow {
            transform: rotate(-45deg);
          }
        }
      }
      .flex-row {
        display: flex;
        flex-wrap: wrap;
        .time-zone {
          display: flex;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          font-family: ${bodyFont};
          margin-right: 20px;
          ${sm(`
            margin: 0 0 20px 0;
          `)}
          select {
            -webkit-appearance: none;
            border: 0;
            background: transparent url(${images.selectArrow}) no-repeat 100%
              50%;
            font-weight: 500;
            margin-left: 7px;
            font-size: 14px;
            line-height: 19px;
            padding-right: 20px;
          }
        }
        .time-slot {
          display: flex;
          font-size: 14px;
          line-height: 19px;
          font-weight: 400;
          font-family: ${bodyFont};
          select {
            -webkit-appearance: none;
            border: 0;
            background: transparent url(${images.selectArrow}) no-repeat 100%
              50%;
            font-weight: 500;
            margin-left: 7px;
            font-size: 14px;
            line-height: 19px;
            padding-right: 20px;
          }
        }
      }
    }
    .submit-row {
      display: flex;
      align-items: center;
      .btn-submit {
        border-radius: 4px;
        padding: 13px 54px 15px;
        background-color: ${themeColor};
        box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
        transition: all 0.15s ease-in-out;
        border: 1px solid ${themeColor};
        position: relative;
        overflow: hidden;
        z-index: 1;
        text-align: center;
        line-height: 19px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        color: ${white};
        display: flex;
        align-items: center;
        ${sm(`
        margin-top: 10px;
        font-size: 14px;
      `)}
        ${lg(`
        padding: 11px 50px 13px;
      `)}
      &:hover {
          color: ${themeColor};
        }
        transition: all 0.15s ease;
        transition: color 0.15s ease-in-out;
        display: inline-block;
        &:after,
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: ${white};
          z-index: -1;
        }
        &:before {
          transform: translateY(-100%);
          transition: all 15ms ease-in-out;
        }
        &:after {
          transform: translateY(100%);
          transition: all 0.2s ease-in-out;
        }
        &:hover:before {
          transform: translateY(0);
          transition: all 0.2s ease-in-out;
        }
        &:hover:after {
          transform: translateY(0);
          transition: all 0ms ease-in-out;
          transition-delay: 0.2s;
        }
      }
      img {
        width: 30px;
        margin-left: 5px;
      }
    }
    .bottomAlert {
      display: flex;
      align-items: center;
      margin-top: 19px;
    }
    .bottomSuccessText {
      color: #3d3d3d;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      padding-left: 12px;
      margin-bottom: -6px;
      ${sm(`
          font-size:14px;
      `)}
    }
    .bottomErrorText {
      color: ${themeColor};
      font-size: 16px;
      font-weight: 500;
      padding-left: 12px;
    }
  }
`
