import styled from 'styled-components'
import { white, xll, sh, xl, lg, md, sm } from 'config/variables'

export const ContactMainSecStyle = styled.section`
padding-top: 150px;
  position: relative;
  z-index: 1;
  margin: 0;
  ${lg(`
    padding-top: 100px;
  `)}
  .container {
    display: flex;
    ${lg(`
      flex-wrap: wrap;
    `)}
    .content-part {
      padding-top: 60px;
      max-width: 238px;
      flex: 0 0 238px;
      width: 100%;
      margin-right: 30px;
      ${xll(`
        max-width: 210px;
      `)}
      ${sh(`
        max-width: 210px;
      `)}
      ${xl(`
        max-width: 210px;
      `)}
      ${lg(`
        max-width: 100%;
        flex: 100%;
        padding: 0;
        margin: 0;
        margin-bottom: 50px;
      `)}
      ${md(`
        display: none;
      `)}
      h3 {
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -0.33px;
        margin-bottom: 26px;
        ${lg(`
          margin-bottom: 10px;
        `)}
        ${md(`
          font-size: 28px;
          line-height: 34px;
        `)}
        ${sm(`
          font-size: 24px;
          line-height: 30px;
        `)}
      }
      p {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.2px;
        ${sm(`
          font-size: 16px;
          line-height: 24px;
        `)}
      }
      .contet-list {
        margin-top: 48px;
        padding-left: 34px;
        ${lg(`
          margin-top: 40px;
          padding: 0;
          display: flex;
          justify-content: space-between;
        `)}
        ${md(`
          margin-top: 30px;
          overflow: auto;
          -webkit-overflow-scrolling: touch;
        `)}
        .list-blk {
          padding-bottom: 290px;
          position: relative;
          ${lg(`
            padding: 0;
            max-width: 31%;
          `)}
          ${md(`
            max-width: initial;
            min-width: 250px;
            padding-right: 20px;
          `)}
          ${sm(`
            min-width: 240px;
            padding-right: 10px;
          `)}
          &:last-child {
            padding-bottom: 0;
          }
          &:after {
            content: '';
            position: absolute;
            width: 2px;
            background: rgba(239, 83, 102, 0.2);
            left: -26px;
            top: 6px;
            height: 100%;
            ${lg(`
              display: none;
            `)}
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
          h4 {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.17px;
            margin-bottom: 10px;
            position: relative;
            &:before {
              content: '';
              width: 18px;
              height: 18px;
              border: 3px solid ${white};
              background: #EF5366;
              position: absolute;
              left: -34px;
              top: 2px;
              border-radius: 50%;
              z-index: 1;
              ${lg(`
                display: none;
              `)}
            }
          }
          p {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.17px;
          }
        }
      }
    }
    .contact-form-part {
      max-width: calc(50vw + 282px);
      flex: 999px;
      width: 100%;
      position: relative;
      padding: 80px 120px 103px 140px;
      background: #F3F5FF;
      ${xll(`
        max-width: calc(50vw + 300px); 
        padding: 70px 70px 73px 80px;
      `)}
      ${sh(`
        max-width: calc(50vw + 254px); 
        padding: 70px 70px 73px 80px;
      `)}
      ${xl(`
        max-width: calc(100% - 220px); 
        padding: 40px 40px 60px 60px;
      `)}
      ${lg(`
        max-width: 100%;
        padding: 40px 40px 60px 60px;
        margin-left: -10px;
      `)}
      ${md(`
        padding: 30px 30px 50px 50px;
      `)}
      ${sm(`
        padding: 30px 15px 50px 35px;
      `)}
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #E1E5FE;
        top: -20px;
        right: -20px;
        z-index: -1;
      }
      .heading {
        display: flex;
        margin-bottom: 20px;
         
        .icon {
          flex: 0 0 90px;
          margin-right: 18px;
          ${lg(`
            flex: 0 0 80px;
          `)}
          ${md(`
            flex: 0 0 70px;
          `)}
          ${sm(`
            flex: 0 0 40px;
            margin-right: 10px;
          `)}
          img {
            display: block;
          }
        }
        h3 {
          font-size: 30px;
          line-height: 36px;
          letter-spacing: -0.33px;
          margin: 0;
          padding-top: 5px;
          ${lg(`
            font-size: 28px;
            line-height: 34px;
          `)}
          ${md(`
            font-size: 24px;
            line-height: 30px;
          `)}
          ${sm(`
            font-size: 21px;
            line-height: 29px;
            padding: 0;
          `)}
        }
      }
      p {
        margin-bottom:30px;
      }
    }
  }
`
